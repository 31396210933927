import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import useAuth from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";

const Button = styled(MuiButton)(spacing);

const Quiz = (props) => {
  const { cardSx } = props;
  const { t } = useTranslation();
  const alert = useAlert();
  const { employeeConnector } = useAuth();
  const [quizState, setQuizState] = useState(null);

  /**
   * Handle opeining external link to quiz
   * @returns {void}
   */
  const handleOpenQuiz = () => {
    if (quizState === null || !quizState.link) return;
    else {
      window.open(quizState.link, "_blank");
    }
  };

  /**
   * Get quiz info for the user
   * @returns {void}
   */
  const getQuizState = useCallback(async () => {
    try {
      const result = await employeeConnector.getQuiz();
      if (result) {
        setQuizState({
          visible: result.data.results.visible,
          open: result.data.results.open,
          link: result.data.results.link,
        });
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[CareerView] getQuiz", error);
    }
  }, [employeeConnector, alert]);

  useEffect(() => {
    if (quizState === null && employeeConnector) {
      getQuizState();
    }
  }, [quizState, getQuizState, employeeConnector]);

  return quizState !== null && quizState.visible ? (
    <Card sx={cardSx}>
      <CardContent>
        <Typography mb="1em" variant="h4">
          {t("career.quiz.title")}
        </Typography>
        <Typography mb="2em">{t("career.quiz.description")}</Typography>

        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            disabled={!quizState.open}
            size="large"
            onClick={handleOpenQuiz}
          >
            {t("career.quiz.answer")}
          </Button>
        </Box>
      </CardContent>
    </Card>
  ) : null;
};

export default Quiz;
