import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import useAuth from "../../hooks/useAuth";

import {
  Box,
  Button as MuiButton,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Card as MuiCard,
  CardContent,
} from "@mui/material";

import { spacing } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";

import { getSamAuthHeaders } from "../../utils/sam.api.js";
import { getSamProxyXHRConfig } from "../../connectors/connector.helpers";
import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import Uploader from "../Uploader";
import { detectMask, clearIban, formatIban } from "./helper-payslips";

import { appConfig } from "../../config.js";

const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const sxTextField = {
  width: "100%",
};

const dzStyle = {
  backgroundColor: "#376fd0",
  color: "#fff",
  height: "fit-content",
  width: "fit-content",
  padding: "0.5em 1em 0.5em 1em",
  borderRadius: "4px",
  display: "flex",
  cursor: "pointer",
  justifyContent: "center",
};

const Rib = (props) => {
  const { onListViewClick, ribRequired } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const { user, employeeConnector } = useAuth();
  const [edit, setEdit] = useState(ribRequired);
  const [rib, setRib] = useState(null);
  const [countryCodes, setCountryCodes] = useState(null);
  const [ibanFile, setIbanFile] = useState(null);
  const [iban, setIban] = useState("");
  const [mask, setMask] = useState("");
  const [loading, setLoading] = useState(false);
  const [dropzoneObject, setDropzoneObject] = useState(null);
  const formik = useFormik({
    initialValues: {
      name: "",
      domiciliation: "",
      bank: "",
      iban: "",
      bic: "",
      file: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validate: (values) => {
      const errors = {};
      if (!ibanFile) errors.file = "File required";
      if (!values.iban) {
        errors.iban = "Iban required";
      } else {
        let ibanSize = 2;
        for (const field of mask) {
          ibanSize += parseInt(field);
        }
        if (!mask || values.iban.length !== ibanSize)
          errors.iban = "Wrong Iban format";
      }
      if (!values.name) errors.name = "Name required";
      if (!values.domiciliation) errors.domiciliation = "Bank address required";
      if (!values.bank) errors.bank = "Bank name required";
      if (!values.bic) errors.bic = "Bic code required";
      return errors;
    },
  });
  const valuesRef = useRef();

  const samAuthHeaders = getSamAuthHeaders(user);
  const xhrConfig = getSamProxyXHRConfig(samAuthHeaders);

  /**
   * Reset formik and state
   * @return {void}
   */
  const resetForm = () => {
    formik.resetForm();
    setIbanFile(null);
    setIban("");
    setMask("");
    console.log("[RIBUploader] resetForm", formik.values);
  };

  /**
   * Handle rib edition
   * @return {void}
   */
  const handleRibEdit = () => {
    console.log("[RIBUploader] handleRibEdit");
    setEdit(true);
  };

  /**
   * Handle cancel rib edition
   * @return {void}
   */
  const handleCancelRibEdit = () => {
    console.log("[RIBUploader] handleCancelRibEdit");
    resetForm();
    setDropzoneObject(null);
    setEdit(false);
  };

  /**
   * Handle file add for upload
   * @param {object} file
   */
  const handleFileAdd = (file) => {
    console.log("[RIBUploader] handleFileUpload", file);
    setIbanFile(file);
  };

  /**
   * Handle file upload
   * @param {Object} values
   * @return {void}
   */
  const handleSubmit = async (values) => {
    setLoading(true);
    await dropzoneObject.processQueue();
    console.log("[RIBUploader] handleSubmit", values, formik.values);
  };

  /**
   * Handle file upload success and form submit
   * @param {Object} response
   * @param {Object} values
   * @return {void}
   */
  const handleFileUploadSuccess = async (response, values) => {
    console.log("[RIBUploader] handleFileUploadSuccess", values);
    const file = response.data.file_id;
    formik.setFieldValue("file", file);
    try {
      const result = await employeeConnector.setRib({ ...values, file });
      if (result) {
        setRib(result.data);
        setEdit(false);
        setLoading(false);
        resetForm();
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[RIBUploader] handleFileUploadSuccess", error);
      setLoading(false);
    }
  };

  /**
   * Handle Uploader initialization
   * @param {Object} dropzone
   * @return {void}
   */
  const handleDZInit = (dropzone) => {
    setDropzoneObject(dropzone);
  };

  /**
   * Handle remove file
   * @return {void}
   */
  const handleDeleteFile = () => {
    console.log("[RIBUploader] handleDeleteFile", dropzoneObject);
    setIbanFile(null);
  };

  /**
   * Handle iban value chaging
   * @param {Object} e
   * @returns {void}
   */
  const handleIbanChange = (e) => {
    const { value } = e.target;
    const cleanIban = clearIban(value);
    if (!mask) {
      setIban(cleanIban);
      detectMask(cleanIban, mask, setMask, countryCodes);
    } else {
      let ibanSize = 2;
      for (const field of mask) {
        ibanSize += parseInt(field);
      }
      if (cleanIban.length > ibanSize) return;
      setIban(formatIban(cleanIban, mask));
    }
    formik.setFieldValue("iban", cleanIban);
  };

  /**
   * Handle rib file download
   * @return {void}
   */
  const handleRibDownload = useCallback(async () => {
    console.log("[RIBUploader] handleRibDownload", rib.file);
    setLoading(true);
    if (!rib || !rib.file) return;
    try {
      const result = await employeeConnector.getRibFile(rib.file);
      if (result) {
        setLoading(false);
        window.open(result.data.url, "_blank");
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[RIBUploader] handleRibDownload", error);
      setLoading(false);
    }
  }, [employeeConnector, rib]);

  /**
   * Get suer rib
   * @return {void}
   */
  const getRib = useCallback(async () => {
    setLoading(true);
    try {
      const result = await employeeConnector.getRib();
      if (result) {
        setRib(result.data);
        setEdit(false);
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[RIBUploader] getRib", error);
      setLoading(false);
    }
  }, [employeeConnector]);

  /**
   * Get country codes and associated masks for iban formating
   * @return {void}
   */
  const getCountryCodes = useCallback(async () => {
    setLoading(true);
    try {
      const result = await employeeConnector.getIbanMasks();
      if (result) {
        setCountryCodes(result.data);
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[RIBUploader] getCountryCodes", error);
      setLoading(false);
    }
  }, [employeeConnector]);

  /**
   * Render uploader
   * @returns {Object}
   */
  const renderUploader = () => {
    return (
      <Box>
        <Box
          display={ibanFile ? "flex" : "none"}
          justifyContent="space-between"
        >
          <Typography
            pt="0.5em"
            sx={{
              maxWidth: "27em",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {t("payslips.rib.your-upload")}: {ibanFile?.name}
          </Typography>
          <Box>
            <IconButton onClick={handleDeleteFile}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
        <Box display={ibanFile ? "none" : "block"}>
          <Box mb="1em">
            <Typography>{`${t(
              "career.documents.acceptedFormat"
            )}: ${appConfig.upload.allowedMimeTypes.join(", ")}`}</Typography>
            <Typography>{`${t("career.documents.maxSize")}: ${
              appConfig.upload.maxFileSize
            }Mo`}</Typography>
          </Box>
          <Uploader
            url={employeeConnector.getRibUploadUrl()}
            id={iban.file || ""}
            dzStyle={dzStyle}
            onInit={handleDZInit}
            onDocumentUpload={handleFileAdd}
            onDocumentUploadSuccess={(response) =>
              handleFileUploadSuccess(response, valuesRef.current)
            }
            autoProcess={false}
            xhrConfig={xhrConfig}
          />
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (rib === null && !ribRequired && employeeConnector && !loading) {
      getRib();
    }
    if (countryCodes === null && edit && employeeConnector && !loading) {
      getCountryCodes();
    }

    valuesRef.current = formik.values;
  }, [
    rib,
    getRib,
    ribRequired,
    countryCodes,
    getCountryCodes,
    edit,
    formik,
    employeeConnector,
    loading,
  ]);

  return (
    <Box>
      <Box display="flex" className="view-title">
        <IconButton onClick={onListViewClick}>
          <ArrowBackIcon />
        </IconButton>

        <Typography variant="h3" gutterBottom display="grid">
          {t("payslips.rib.title")}
        </Typography>
      </Box>

      <Card>
        <CardContent className="card-content">
          {loading ? (
            <Box mt="1em" display="flex" justifyContent="center">
              <CircularProgress />
              <Typography pt="0.8em" ml="1em">
                {t("loader.loading")}
              </Typography>
            </Box>
          ) : (
            <Box display="grid" justifyContent="center">
              <Typography mb="1em">{t("payslips.rib.description")}</Typography>
              {edit ? (
                renderUploader()
              ) : (
                <Box>
                  {rib?.file ? (
                    <Box display="flex" justifyContent="space-between">
                      <Typography pt="0.5em">
                        {t("payslips.rib.file-uploaded")}
                      </Typography>
                      <IconButton onClick={handleRibDownload}>
                        <DownloadIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <Typography>{t("payslips.rib.no-file")}</Typography>
                  )}
                </Box>
              )}
              <Box mt="2em">
                <form onSubmit={formik.handleSubmit}>
                  <Box sx={sxTextField} mr="1em" mb="1em">
                    <Typography mb="0.5em" variant="h6">
                      {t("payslips.rib.name")}
                    </Typography>
                    <TextField
                      sx={sxTextField}
                      InputProps={{
                        disabled: !edit,
                        required: edit,
                        endAdornment: !edit ? (
                          <InputAdornment position="end">
                            <LockIcon />
                          </InputAdornment>
                        ) : null,
                      }}
                      inputProps={{ maxLength: 50 }}
                      id="name"
                      name="name"
                      value={edit ? formik.values.name : rib?.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur("name")}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Box>
                  <Box sx={sxTextField} mr="1em" mb="1em">
                    <Typography mb="0.5em" variant="h6">
                      {t("payslips.rib.domiciliation")}
                    </Typography>
                    <TextField
                      sx={sxTextField}
                      InputProps={{
                        disabled: !edit,
                        required: edit,
                        endAdornment: !edit ? (
                          <InputAdornment position="end">
                            <LockIcon />
                          </InputAdornment>
                        ) : null,
                      }}
                      inputProps={{ maxLength: 250 }}
                      id="domiciliation"
                      name="domiciliation"
                      value={
                        edit ? formik.values.domiciliation : rib?.domiciliation
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur("domiciliation")}
                      error={
                        formik.touched.domiciliation &&
                        Boolean(formik.errors.domiciliation)
                      }
                      helperText={
                        formik.touched.domiciliation &&
                        formik.errors.domiciliation
                      }
                    />
                  </Box>
                  <Box sx={sxTextField} mr="1em" mb="1em">
                    <Typography mb="0.5em" variant="h6">
                      {t("payslips.rib.bank")}
                    </Typography>
                    <TextField
                      sx={sxTextField}
                      InputProps={{
                        disabled: !edit,
                        required: edit,
                        endAdornment: !edit ? (
                          <InputAdornment position="end">
                            <LockIcon />
                          </InputAdornment>
                        ) : null,
                      }}
                      inputProps={{ maxLength: 250 }}
                      id="bank"
                      name="bank"
                      value={edit ? formik.values.bank : rib?.bank}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur("bank")}
                      error={formik.touched.bank && Boolean(formik.errors.bank)}
                      helperText={formik.touched.bank && formik.errors.bank}
                    />
                  </Box>
                  <Box sx={sxTextField} mr="1em" mb="1em">
                    <Typography mb="0.5em" variant="h6">
                      {t("payslips.rib.iban")}
                    </Typography>
                    <TextField
                      sx={sxTextField}
                      InputProps={{
                        disabled: !edit,
                        required: edit,
                        endAdornment: !edit ? (
                          <InputAdornment position="end">
                            <LockIcon />
                          </InputAdornment>
                        ) : null,
                      }}
                      id="iban"
                      name="iban"
                      value={edit ? iban : rib?.iban}
                      onChange={handleIbanChange}
                      onBlur={formik.handleBlur("iban")}
                      error={formik.touched.iban && Boolean(formik.errors.iban)}
                      helperText={formik.touched.iban && formik.errors.iban}
                    />
                  </Box>
                  <Box sx={sxTextField} mr="1em" mb="1em">
                    <Typography mb="0.5em" variant="h6">
                      {t("payslips.rib.bic")}
                    </Typography>
                    <TextField
                      sx={sxTextField}
                      InputProps={{
                        disabled: !edit,
                        required: edit,
                        endAdornment: !edit ? (
                          <InputAdornment position="end">
                            <LockIcon />
                          </InputAdornment>
                        ) : null,
                      }}
                      id="bic"
                      name="bic"
                      value={edit ? formik.values.bic : rib?.bic}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur("bic")}
                      error={formik.touched.bic && Boolean(formik.errors.bic)}
                      helperText={formik.touched.bic && formik.errors.bic}
                    />
                  </Box>
                  <Box
                    display={fullScreen ? "grid" : "block"}
                    justifyContent="center"
                  >
                    {edit ? (
                      <Box>
                        {rib ? (
                          <Button
                            mr="1em"
                            variant="contained"
                            onClick={handleCancelRibEdit}
                          >
                            {t("payslips.rib.cancel")}
                          </Button>
                        ) : null}
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={!formik.dirty || !formik.isValid}
                        >
                          {t("payslips.rib.save")}
                        </Button>
                      </Box>
                    ) : (
                      <Button variant="contained" onClick={handleRibEdit}>
                        {t("payslips.rib.edit")}
                      </Button>
                    )}
                  </Box>
                </form>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Rib;
