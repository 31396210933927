import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";

import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { isMobileDevice } from "../../helpers/common.helpers";

const HealthCare = (props) => {
  const { id } = props;
  const { employeeConnector } = useAuth();
  const alert = useAlert();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [choice, setChoice] = useState(null);
  const [healthcare, setHealthcare] = useState(null);
  const [loading, setLoading] = useState(false);
  const [signUrl, setSignUrl] = useState(null);

  /**
   * Handle return to list
   * @return {void}
   */
  const handleReturnClick = () => {
    console.log("[HealthCare] handleReturnClick");
    navigate(`/contracts/healthcares`);
  };

  /**
   * Handle choice validation
   * @return {void}
   */
  const handleValidateClick = async () => {
    setLoading(true);
    console.log("[HealthCare] handleValidateClick", choice);
    const data = {
      choice,
    };
    try {
      console.log("[HealthCare] handleValidateClick", data);
      const result = await employeeConnector.postHealthcareChoice(id, data);
      if (result) {
        const url = result.data.results.url;
        console.log("[HealthCare] getSignUrl", url);
        if (!isMobileDevice()) setSignUrl(url);
        else window.open(url, "_blank").focus();

        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[HealthCare] handleValidateClick", error);
    }
  };

  /**
   * Handle choice selection
   * @param {Object} e
   * @param {String} value
   * @return {void}
   */
  const handleChoiceChange = (e, value) => {
    console.log("[HealthCare] handleChoiceChange", value);
    setChoice(value);
  };

  const handleEmailClick = async () => {
    console.log("[HealthCare] handleEmailClick");
    try {
      await navigator.clipboard.writeText(healthcare.email);
      alert.success({ message: t("alert.success.to-clipboard") });
    } catch (error) {
      alert.error({ message: error });
      console.error("[HealthCare] handleEmailClick", error);
    }
  };

  /**
   * Get an healthcare sign url of user
   * @returns {void}
   */
  const getSignUrl = useCallback(async () => {
    setLoading(true);
    try {
      const result = await employeeConnector.getHealthcareSignature(id);
      if (result) {
        const url = result.data.results.url;
        console.log("[HealthCare] getSignUrl", url);
        if (!isMobileDevice()) setSignUrl(url);
        else window.open(url, "_blank").focus();

        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[HealthCare] getSignUrl", error);
    }
  }, [employeeConnector, alert, id]);

  /**
   * Get an healthcare of user
   * @returns {void}
   */
  const getHealthcare = useCallback(async () => {
    setLoading(true);
    try {
      const result = await employeeConnector.getHealthcare(id);
      if (result) {
        const hc = result.data.results[0];
        setHealthcare(hc);
        if (hc.to_sign) {
          getSignUrl();
        } else setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[HealthCare] getHealthcare", error);
    }
  }, [employeeConnector, alert, getSignUrl, id]);

  const renderChoice = () => {
    return (
      <Box display="grid" justifyContent="center">
        <Typography>{t("contract.healthcare.fee")}</Typography>
        <Typography mt="1em">{t("contract.healthcare.resiliation")}</Typography>
        <Typography mt="1em">{t("contract.healthcare.inflation")}</Typography>
        <Box mt="1em" mb="1em">
          <Link
            href={healthcare.url_garanties}
            target="_blank"
            sx={{ cursor: "pointer" }}
          >
            {t("contract.healthcare.waranties-link")}
          </Link>
        </Box>
        <FormControl>
          <RadioGroup
            row={!isMobileDevice()}
            value={choice}
            onChange={handleChoiceChange}
          >
            <FormControlLabel
              sx={{ marginRight: "5em" }}
              value="yes"
              control={<Radio />}
              label={t("contract.healthcare.yes-choice")}
            />
            <FormControlLabel
              value="no"
              control={<Radio />}
              label={t("contract.healthcare.no-choice")}
            />
          </RadioGroup>
        </FormControl>
        <Box mt="1em" justifyContent="right">
          <LoadingButton
            variant="contained"
            disabled={!choice}
            onClick={handleValidateClick}
            loading={loading}
          >
            {t("contract.healthcare.validate")}
          </LoadingButton>
        </Box>
        <Typography mt="1em">{t("contract.healthcare.information")}</Typography>
      </Box>
    );
  };

  const renderComplete = () => {
    return (
      <Box>
        <Typography variant="h4" gutterBottom display="grid">
          {t("contract.section.complete-title")}
        </Typography>
        <Box display="grid" justifyContent="center">
          <Typography mb="1em">
            {t("contract.healthcare.you-accepted")}
          </Typography>
          <Box mb="1em">
            <Link href={healthcare.url_bulletin} target="_blank">
              {t("contract.healthcare.form-link")}
            </Link>
          </Box>
          <Typography mb="1em">
            {t("contract.healthcare.send-us")}{" "}
            <span>
              <Link onClick={handleEmailClick} sx={{ cursor: "pointer" }}>
                {healthcare.email}
              </Link>
            </span>
          </Typography>
          <Typography>{t("contract.healthcare.when-complete")}</Typography>
          <Box mb="1em">
            <List disablePadding>
              <ListItem disablePadding>
                <ListItemText primary={t("contract.healthcare.card")} />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText primary={t("contract.healthcare.web-access")} />
              </ListItem>
            </List>
          </Box>

          <Typography mb="1em">{t("contract.healthcare.will-end")}</Typography>
          <Typography>{t("contract.healthcare.more-info")}</Typography>
        </Box>
      </Box>
    );
  };

  const renderSigning = () => {
    return (
      <Box mt="1em">
        <Typography variant="h4" gutterBottom display="grid">
          {t(
            `contract.healthcare.${
              choice === "yes" ? "accept" : "refuse"
            }-title`
          )}
        </Typography>
        <iframe
          style={{ width: "100em", height: "70em" }}
          title="sign-contract"
          src={signUrl}
        />
      </Box>
    );
  };

  const renderStep = () => {
    if (!healthcare) return null;
    if (signUrl && !isMobileDevice()) return renderSigning();

    return healthcare.to_read_next_step ? renderComplete() : renderChoice();
  };

  useEffect(() => {
    if (healthcare === null && employeeConnector && !loading) {
      getHealthcare();
    }
  }, [healthcare, employeeConnector, getHealthcare, loading]);

  return (
    <Box mt="1em">
      <Box display="flex">
        <IconButton onClick={handleReturnClick}>
          <ArrowBackIcon />
        </IconButton>

        <Typography
          variant="h3"
          gutterBottom
          display="grid"
          className="view-title"
        >
          {t("contract.section.healthcare")}
        </Typography>
      </Box>

      {loading && !healthcare ? (
        <Box display="grid" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        renderStep()
      )}
    </Box>
  );
};

export default HealthCare;
