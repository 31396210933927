import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import { Grid, Typography } from "@mui/material";

import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";
// import { appConfig } from "../../config.js";

import { parsePlanning } from "./helper";
import PlanningView from "./PlanningView.js";

function Planning() {
  const { employeeConnector } = useAuth();
  const { t } = useTranslation();
  const alert = useAlert();
  const [employeePlanning, setEmployeePlanning] = useState(null);
  const [loading, setLoading] = useState(false);

  const getPlanning = useCallback(
    async (dates) => {
      setLoading(true);
      try {
        const response = await employeeConnector.getPlanning(dates);
        if (response) {
          alert.success({ message: t("alert.success.loading") });
          const planning = response.data.results;
          console.log("[Planning] getPlanning:", planning);
          setEmployeePlanning(parsePlanning(planning));
          setLoading(false);
        }
      } catch (error) {
        console.error("[Planning] getPlanning", error);
        setLoading(false);
        alert.error({ message: parseResponseErrorMessage(error) });
      }
    },
    [employeeConnector, alert, t]
  );

  useEffect(() => {
    if (employeePlanning === null && employeeConnector && !loading) {
      const startDate = new Date();
      startDate.setDate(1);
      let endDate;
      if (startDate.getMonth() === 11) {
        endDate = new Date(startDate.getFullYear() + 1, 0, 1);
      } else {
        endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 1,
          1
        );
      }
      getPlanning({ startDate, endDate });
      console.log(
        "[Planning] getPlanning:",
        employeePlanning,
        employeeConnector
      );
    }
  }, [employeePlanning, getPlanning, employeeConnector, loading]);

  return (
    <React.Fragment>
      <Helmet title={t("menu.planning")} />
      <Typography
        variant="h3"
        gutterBottom
        display="grid"
        className="view-title"
      >
        {t("menu.planning")}
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <PlanningView
            events={employeePlanning || []}
            onMonthChange={employeeConnector ? getPlanning : () => null}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Planning;
