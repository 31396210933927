import React from "react";
import Logger from "../Logger";

/**
 * Handle errors
 *   https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html
 *   https://codepen.io/gaearon/pen/wqvxGa?editors=0010
 *   https://www.loggly.com/blog/best-practices-for-client-side-logging-and-error-handling-in-react/
 * @returns void
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.location = props?.location;
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   return { error: true };
  // }

  componentDidCatch(error, errorInfo) {
    console.error(
      "[ErrorBoundary] componentDidCatch",
      error,
      errorInfo,
      this.location
    );

    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });

    // You can also log error messages to an error reporting service here
    Logger.push({
      error: error ? error.toString() : null,
      errorInfo: errorInfo?.componentStack,
      pathname: this.location?.pathname,
    });
  }

  render() {
    const { errorInfo, error } = this.state;
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;

    if (errorInfo) {
      // Error path
      return (
        <div>
          <h2>Hum, hum ... Something went wrong ...</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        </div>
      );
    }

    // Normally, just render children
    return children;
  }
}

export default ErrorBoundary;
