import React from "react";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// App Pages
import Mandatory from "./pages/mandatory/Mandatory";
import Planning from "./pages/planning/Planning";
import Contracts from "./pages/contracts/Contracts";
import Payslips from "./pages/payslips/Payslips";
import Resources from "./pages/resources/Resources";
import JobOffers from "./pages/job-offers/JobOffers";
import MyOffers from "./pages/my-offers/MyOffers";
import MyBlankOffers from "./pages/my-offers/MyBlankOffers";
import UserProfile from "./pages/profile/UserProfile";
import JobOfferView from "./pages/job-offers/JobOfferView";
import Career from "./pages/career/Career";

// Auth components
// import Auth0SignIn from "./pages/auth/Auth0SignIn";
// import Auth0SignInSuccess from "./pages/auth/Auth0SignInSuccess";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import SignOut from "./pages/auth/SignOut";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import AutoSignIn from "./pages/auth/AutoSignIn";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Page components
import Home from "./pages/pages/Home";
// import Blank from "./pages/pages/Blank";
import Settings from "./pages/pages/Settings";
import Navbar from "./components/navbar/Navbar";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "profile",
        element: <UserProfile />,
      },
      {
        path: "job-offers",
        element: <JobOffers />,
      },
      {
        path: "job-offers/:id",
        element: <JobOfferView />,
      },
      {
        path: "my-offers",
        element: <MyOffers />,
      },
      {
        path: "my-blank-offers",
        element: <MyBlankOffers />,
      },
      {
        path: "my-offers/:filter",
        element: <MyOffers />,
      },
      {
        path: "payslips",
        element: <Payslips />,
      },
      {
        path: "planning",
        element: <Planning />,
      },
      {
        path: "contracts/:section",
        element: <Contracts />,
      },
      {
        path: "contracts/:section/:id",
        element: <Contracts />,
      },
      {
        path: "resources",
        element: <Resources />,
      },
      {
        path: "career",
        element: <Career />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },

  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      // {
      //   path: "auth0-sign-in",
      //   element: <Auth0SignIn />,
      // },
      // {
      //   path: "success",
      //   element: <Auth0SignInSuccess />,
      // },
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "sign-out",
        element: <SignOut />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "auto-sign-in",
        element: <AutoSignIn />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },

  {
    path: "*",
    element: (
      <>
        <Navbar />
        <AuthLayout />
      </>
    ),
    children: [
      {
        path: "*",
        element: <Page404 />,
      },

      {
        path: "mandatory-action",
        element: <Mandatory />,
      },
    ],
  },
];

export default routes;
