import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Dialog, Grid, DialogContent, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";

import OnBoarding from "../../components/onBoarding/OnBoarding";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";
import DashBoardView from "../dashboard/DashboardView";

function Home() {
  const { isAuthenticated, user, setAppUserOnboarding, employeeConnector } =
    useAuth();
  const [openOnBoarding, setOpenOnBoarding] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const alert = useAlert();

  console.log("[Home] isAuthenticated:", isAuthenticated);
  console.log("[Home] user: ", user);

  const handleCloseOnBoarding = async () => {
    setOpenOnBoarding(!openOnBoarding);
    try {
      const data = {
        onboarding: 1,
      };
      const response = await employeeConnector.updateOnBoarding(data);
      if (response) {
        setAppUserOnboarding(response.onboarding);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[Home] handleCloseOnBoarding", error);
    }
  };

  const handleDefaultCloseDialog = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (openOnBoarding === null && user)
      setOpenOnBoarding(user.onboarding === 0);
  }, [openOnBoarding, user]);

  return (
    <React.Fragment>
      <Helmet title={t("menu.home")} />
      <Typography
        variant="h3"
        gutterBottom
        display="grid"
        className="view-title"
      >
        {t("menu.home")}
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <DashBoardView />
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        open={openOnBoarding || false}
        onClose={handleDefaultCloseDialog}
      >
        <DialogContent
          sx={
            fullScreen
              ? {
                  padding: 0,
                }
              : {
                  padding: 0,
                  height: 600,
                  width: 900,
                }
          }
        >
          <OnBoarding onClose={handleCloseOnBoarding} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default Home;
