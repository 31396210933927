import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  IconButton,
  Typography,
} from "@mui/material/";
import LoadingButton from "@mui/lab/LoadingButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import useSearchJob from "../../hooks/useSearchJob";
import { spacing } from "@mui/system";

import { getChildrenAges } from "./user.helpers";
import {
  scrollAppContentToTop,
  isPushesView,
} from "../../helpers/common.helpers";
import { formatDate } from "../../helpers/dates.helpers";

import Schedule from "../offer/Schedule";
import TravelTime from "../offer/TravelTime";
import Apply from "../offer/Apply";

const Divider = styled(MuiDivider)(spacing);

const cardSx = {
  marginBottom: "1em",
};

/**
 * Returns JobOffer
 * @param {Object} props
 * @returns {ReactComponent}
 */
export default function JobOffer(props) {
  const { t, i18n } = useTranslation();
  const { user, employeeConnector } = useAuth();
  const alert = useAlert();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    currentJobOffer,
    initCurrentJobOffer,
    // repositories,
    currentView,
    // getServiceRepository,
    getLanguageRepository,
    getLanguageLevelRepository,
  } = useSearchJob();

  const [hasViewScrolledToTop, setHasViewScrolledToTop] = useState(false);
  const [applying, setApplying] = useState(false);
  const [offerStatus, setOfferStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(null);

  const language = i18n.language;

  /* Obsolete
  const jobOfferEmployeeInfo = useMemo(
    () => ({
      bookmarked:
        user.jobOffers.bookmarks.filter(
          (jobOffer) => jobOffer.requestId === currentJobOffer.id
        ).length > 0,
      applied:
        user.jobOffers.applications.filter(
          (jobOffer) => jobOffer.requestId === currentJobOffer.id
        ).length > 0,
      status:
        user.jobOffers.applications.filter(
          (jobOffer) => jobOffer.requestId === currentJobOffer.id
        )[0]?.status || null,
      deleted:
        user.jobOffers.deletions.filter(
          (jobOffer) => jobOffer.requestId === currentJobOffer.id
        ).length > 0,
    }),
    [user, currentJobOffer]
  );

  // eslint-disable-next-line prettier/prettier

  const jobOffer = useMemo(
    () => ({ ...currentJobOffer, ...jobOfferEmployeeInfo }),
    [currentJobOffer, jobOfferEmployeeInfo]
  );*/

  const jobOffer = currentJobOffer;

  console.log("[JobOffer]", props, id, jobOffer, user);
  console.log("[JobOffer] - currentView", currentView);

  // scroll JobOffer to top on 1st render
  scrollAppContentToTop();

  /**
   * Redirect back to SearchJob view
   * @param {Object} event
   * @returns {void}
   */
  const handleHistoryBackClick = (event) => {
    event.preventDefault();

    // init current JobOffer
    initCurrentJobOffer();

    // redirect to current view
    const parentView = currentView ? currentView : "job-offers";
    navigate(`/${parentView}`);
  };

  /**
   * JobOffer Apply
   * @param {Object} event
   * @returns {void}
   */
  const handleApply = () => {
    setApplying(true);
  };

  /**
   * JobOffer cancel Apply
   * @param {Object} event
   * @returns {void}
   */
  const handleCancelApply = () => {
    setApplying(false);
  };

  /**
   * JobOffer Unapply
   * @param {Object} event
   * @returns {void}
   */
  const handleUnapply = async (event) => {
    event.preventDefault();
    console.log("[JobOffer] handleUnapply", jobOffer);

    const { id } = jobOffer;
    setLoading(true);

    try {
      const response = await employeeConnector.unapplyJobOffer(id);

      if (response && response.data) {
        user.jobOffers.applications = response.data;

        alert.success({
          message: t("alert.success.job-offer-unapplied", { jobOffer }),
        });
        setLoading(false);
        getOfferStatus();
      } else {
        alert.error({ message: "invalid response !" });
        setLoading(false);
        console.error("[Apply] handleUnapply", "invalid response !");
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[JobOffer] handleUnapply", error);
    }
  };

  /**
   * JobOffer Bookmark
   * @param {Object} event
   * @returns {void}
   */
  const handleBookmark = async (event) => {
    event.preventDefault();
    console.log("[JobOffer] handleBookmark", jobOffer);

    try {
      const response = isBookmarked
        ? await employeeConnector.unBookmarkJobOffer(jobOffer.id)
        : await employeeConnector.bookmarkJobOffer(jobOffer.id);

      if (response && response.data) {
        user.jobOffers.bookmarks = response.data;

        setIsBookmarked(!isBookmarked);

        console.log(
          "[JobOffer] handleBookmark - jobOffers.bookmarks updated",
          user.jobOffers.bookmarks
        );

        alert.success({
          message: isBookmarked
            ? t("alert.success.job-offer-unbookmarked", { jobOffer })
            : t("alert.success.job-offer-bookmarked", { jobOffer }),
        });
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[JobOffer] handleBookmark", error);
    }
  };

  /**
   * JobOffer Delete (from pushes)
   * @param {Object} event
   * @returns {void}
   */
  const handleDelete = async (event) => {
    event.preventDefault();

    console.log("[JobOffer] handleDelete", jobOffer);

    const { id } = jobOffer;

    try {
      const response = await employeeConnector.deleteJobOffer(id);

      if (response && response.data) {
        user.jobOffers.deletions = response.data;

        const deletedJobOfferIds = user.jobOffers.deletions.map((jobOffer) => {
          return jobOffer.requestId;
        });

        const updatedPushedJobOffers = user.jobOffers.pushes.filter(
          (jobOffer) => {
            return !deletedJobOfferIds.includes(jobOffer.requestId);
          }
        );
        user.jobOffers.pushes = updatedPushedJobOffers;

        // console.log(
        //   "[JobOffer] handleDelete : jobOffers.pushes updated",
        //   user.jobOffers.pushes
        // );

        alert.success({
          message: t("alert.success.job-offer-deleted", { jobOffer }),
        });
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[JobOffer] handleDelete", error);
    }
  };

  /**
   * Returns delete Button
   * @returns {ReactComponent}
   */
  const getDeleteButton = () => {
    return (
      <IconButton onClick={(e) => handleDelete(e)}>
        <DeleteOutlineIcon />
      </IconButton>
    );
  };

  /**
   * Returns Languages Level
   * @returns {Array}
   */
  const getLanguagesLevel = () => {
    const minimumLevels = {};
    const items = [];

    for (const languageLevel of jobOffer.languages_levels) {
      minimumLevels[languageLevel.split("-")[0]] = languageLevel.split("-")[1];
    }

    for (const languageCode in minimumLevels) {
      const languageLevelCode = minimumLevels[languageCode];

      items.push(
        <Typography
          key={`${languageCode}-${minimumLevels[languageCode]}`}
        >{`${getLanguageRepository(languageCode, language)}
       : ${getLanguageLevelRepository(
         languageLevelCode,
         language
       )}`}</Typography>
      );
    }

    return items;
  };

  /**
   * Returns OfferStatusMessage
   * @returns {ReactComponent}
   */
  const getOfferStatusMessage = () => {
    let message = t("job-offer.dealstatus.none"); // default

    if (offerStatus?.message) {
      message = offerStatus.message;
    } else if (offerStatus?.status) {
      message = t(`job-offer.dealstatus.${offerStatus.status}`);
    }

    return <Typography>{message}</Typography>;
  };

  const getOfferStatus = useCallback(async () => {
    setLoading(true);
    try {
      const result = await employeeConnector.getJobOfferStatus(jobOffer.id);
      if (result) {
        setOfferStatus({
          canApply: result.data.results.request.to_apply,
          canUnapply: result.data.results.request.to_unapply,
          canBookmark: result.data.results.request.to_favory,
          message: result.data.results.request_message,
          status: result.data.results.offer.status,
        });
        setIsBookmarked(result.data.results.request.in_favory);
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[JobOffer] getOfferStatus", error);
    }
  }, [employeeConnector, alert, jobOffer]);

  useEffect(() => {
    console.log("[JobOffer] useEffect");

    if (!currentJobOffer) {
      console.log("[JobOffer] useEffect - no currentJobOffer !");
    }

    if (!hasViewScrolledToTop) {
      // scroll JobOffer to top on 1st render
      scrollAppContentToTop();

      setHasViewScrolledToTop(true);
    }

    if (offerStatus === null && !loading && employeeConnector) {
      getOfferStatus();
    }
  }, [
    currentJobOffer,
    hasViewScrolledToTop,
    offerStatus,
    getOfferStatus,
    employeeConnector,
    loading,
  ]);

  return applying ? (
    <Apply jobOffer={jobOffer} onCancel={handleCancelApply} />
  ) : (
    <>
      <header>
        <Box
          mb="1em"
          display="flex"
          justifyContent="space-between"
          className="view-title"
        >
          <IconButton href="/job-offers" onClick={handleHistoryBackClick}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h3" className="title">
            {t("job-offer.offer") + ` n°${id}`}
          </Typography>

          <div className="action-buttons-container">
            {isPushesView(currentView) && getDeleteButton()}

            <IconButton
              onClick={handleBookmark}
              disabled={!offerStatus?.canBookmark}
            >
              {isBookmarked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </IconButton>
          </div>
        </Box>
      </header>
      <Card sx={cardSx}>
        <CardContent>
          <Typography variant="h4">{t("job-offer.status")}</Typography>
          <Divider my={6} />
          {loading ? (
            <Box mt="1em">
              <CircularProgress />
            </Box>
          ) : (
            <Box>{getOfferStatusMessage()}</Box>
          )}
        </CardContent>
      </Card>
      <Card sx={cardSx}>
        <CardContent>
          <Typography variant="h4">{t("job-offer.detail")}</Typography>
          <Divider my={6} />
          <Typography variant="h6" className="label">
            {t("job-offer.type")}
          </Typography>
          <Typography>
            {t(`job-offer.sub-types.${jobOffer.sub_type}`)}
            {/* {getServiceRepository(jobOffer.service, language)}{" "} */}
            {/* {getLanguageRepository(jobOffer.language_1, language)} */}
          </Typography>
          <Typography variant="h6" className="label">
            {t("job-offer.languageLevel")}
          </Typography>
          {getLanguagesLevel()}
          <Typography variant="h6" className="label">
            {t("job-offer.dates")}
          </Typography>
          <Box>
            <Typography>
              {t("job-offer.start")} -{" "}
              {formatDate(jobOffer.start_date, language)}
            </Typography>
            <Typography>
              {t("job-offer.end")} - {formatDate(jobOffer.end_date, language)}
            </Typography>
          </Box>

          <Typography variant="h6" className="label">
            {t("job-offer.schedule")}
          </Typography>

          <Typography mb="1em">
            {jobOffer.hours_by_week}h / {t("job-offer.perWeek")}
          </Typography>

          <Schedule offer={jobOffer} />

          <Typography variant="h6" className="label">
            {t("job-offer.client")}
          </Typography>
          <Typography>
            {jobOffer[`children_description_${language}`]}
          </Typography>
          <Typography>{getChildrenAges(jobOffer.children_ages, t)}</Typography>
          <Typography variant="h6" className="label">
            {t("job-offer.location")}
          </Typography>
          <Typography>{jobOffer.city}</Typography>
          <Typography variant="h6" className="label">
            {t("job-offer.salary")}
          </Typography>
          <Typography mb="1em">
            {`${jobOffer.package}€ / ${t("job-offer.hour")}`}
          </Typography>
          <Typography>
            {`${t("job-offer.grossSalary")} / ${t("job-offer.hour")} : ${
              jobOffer.gross_salary
            }€`}
          </Typography>
          <Typography>
            {`${t("job-offer.paidLeave")} / ${t("job-offer.hour")} : ${
              jobOffer.paid_leave
            }€`}
          </Typography>
          <Typography>
            {`${t("job-offer.transportation")} / ${t("job-offer.hour")} : ${
              jobOffer.transportation
            }€`}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={cardSx}>
        <CardContent>
          <Typography variant="h4">{t("job-offer.travelTime")}</Typography>
          <Divider my={6} />
          <TravelTime
            offer={jobOffer}
            calculationDone={(value) =>
              console.log("TravelTime calculated:", value)
            }
            onTravelInfosChange={(done) => done}
          />
        </CardContent>
      </Card>
      <Box display="flex" justifyContent="center">
        {offerStatus && offerStatus?.canApply ? (
          <LoadingButton
            variant="contained"
            onClick={handleApply}
            loading={loading}
          >
            {t("job-offer.apply")}
          </LoadingButton>
        ) : null}

        {offerStatus && offerStatus?.canUnapply && !offerStatus?.canApply ? (
          <LoadingButton
            variant="contained"
            color="error"
            onClick={handleUnapply}
            loading={loading}
          >
            {t("job-offer.unapply")}
          </LoadingButton>
        ) : null}
      </Box>
    </>
  );
}
