import React, { createContext, useState, useReducer, useEffect } from "react";

// import { appConfig } from "../config.js";
import { getSamAuthHeaders } from "../utils/sam.api.js";

import RepositoryConnector from "../connectors/sam-api/RepositoryConnector";
import { getSamProxyXHRConfig } from "../connectors/connector.helpers";

import { getStoredUser } from "../helpers/auth.helpers";

import { LOCALSTORAGE_ITEM_KEYS } from "../constants.js";
import { appConfig } from "../config.js";

const INITIALIZE = "INITIALIZE";

const initialState = {
  repositories: null,
};

const reducer = (state, action) => {
  if (action.type === INITIALIZE) {
    const { repositories } = action.payload;
    return {
      ...state,
      repositories,
    };
  }
  return state;
};

const SearchJobContext = createContext(null);

function SearchJobProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [currentJobOffer, setCurrentJobOffer] = useState(null);
  const [currentFilters, setCurrentFilters] = useState(null);
  const [currentSearchResults, setCurrentSearchResults] = useState(null);
  const [currentGeoLocation, setCurrentGeoLocation] = useState(null);

  // current view : 'job-offers'|'my-offers'
  const [currentView, setCurrentView] = useState(null);

  // authenticated user
  const user = getStoredUser();

  useEffect(() => {
    const initialize = async () => {
      console.info("[SearchJobProvider] initialize", state);
      try {
        if (user && !state.repositories) {
          let repositories = null;

          // we retrieve repositories from localstorage
          const storedRepositories = getStoredRepositories();

          if (
            storedRepositories &&
            storedRepositories.version === appConfig.version
          ) {
            repositories = storedRepositories;
          } else {
            // we fetch a newer version
            repositories = await getRepositories(user);

            // we store it
            setStoredRepositories(repositories, appConfig.version);
          }

          console.log(
            "[SearchJobProvider] initialize - repositories:",
            repositories
          );

          dispatch({
            type: INITIALIZE,
            payload: { repositories: repositories },
          });
        }
      } catch (error) {
        console.error("[SearchJobProvider] error:", error);
        dispatch({
          type: INITIALIZE,
          payload: { repositories: null },
        });
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const initCurrentJobOffer = () => {
    setCurrentJobOffer(null);
  };

  const updateCurrentJobOffer = (jobOffer) => {
    setCurrentJobOffer(jobOffer);
  };

  const initCurrentFilters = () => {
    setCurrentFilters([]);
  };

  const updateCurrentFilters = (filters) => {
    // const _filters = { ...filters };
    // // remove null values ... // NB : infinite loops ...
    // for (const key of Object.keys(_filters)) {
    //   if (!_filters[key].value) {
    //     delete _filters[key];
    //   }
    // }
    console.log("[SearchJobProvider] updateCurrentFilters", filters);
    setCurrentFilters(filters);
    // setCurrentFilters(_filters);
  };

  const initCurrentSearchResults = () => {
    setCurrentSearchResults([]);
  };

  const updateCurrentSearchResults = (searchResults) => {
    setCurrentSearchResults(searchResults);
  };

  // TODO : get user coords ?
  const initCurrentGeoLocation = () => {
    console.log("[SearchJobProvider] initCurrentGeoLocation");
    setCurrentGeoLocation(null);
  };

  /**
   * Fetch all Repositories
   * @param {Object} user
   * @returns {Object}
   */
  const getRepositories = async (user) => {
    try {
      if (user) {
        const samAuthHeaders = getSamAuthHeaders(user);
        const repositoryConnector = new RepositoryConnector(
          getSamProxyXHRConfig(samAuthHeaders)
        );
        return await repositoryConnector.getAll();
      }
      return null;
    } catch (error) {
      console.error("[SearchJobProvider] getRepositories", error);

      throw error;
    }
  };

  /**
   * Store Repositories to localStorage
   * @param {Object} repositories
   * @param {String} version
   * @returns {Object}
   */
  const setStoredRepositories = (repositories, version = null) => {
    // console.info("[AuthProvider] setStoredRepositories - version:", version);

    repositories.version = version;

    window.localStorage.setItem(
      LOCALSTORAGE_ITEM_KEYS.REPOSITORIES,
      JSON.stringify(repositories)
    );
  };

  /**
   * Remove Stored Repositories from localStorage
   * @returns {Object}
   */
  const removeStoredRepositories = () => {
    // console.info("[AuthProvider] removeStoredUser");
    window.localStorage.removeItem(LOCALSTORAGE_ITEM_KEYS.REPOSITORIES);
  };

  /**
   * Get Stored Repositories from localStorage
   * @returns {Object}
   */
  const getStoredRepositories = () => {
    // console.info("[getStoredUser]");
    try {
      let repositories = window.localStorage.getItem(
        LOCALSTORAGE_ITEM_KEYS.REPOSITORIES
      );

      if (repositories) {
        return JSON.parse(repositories);
      }
      return null;
    } catch (error) {
      console.error("[getStoredRepositories]", error);
    }
  };

  /**
   * Returns Service Repository
   * @param {String} serviceCode
   * @param {String} language
   * @returns {String}
   */
  const getServiceRepository = (serviceCode, language = "fr") => {
    console.log("[AuthProvider] getServiceRepository", serviceCode, language);
    const { repositories } = state;
    const serviceRepository =
      typeof repositories.services[serviceCode] !== "undefined"
        ? repositories.services[serviceCode]
        : null;

    if (serviceRepository) {
      return typeof serviceRepository[language] !== "undefined"
        ? serviceRepository[language]
        : null;
    }
    return null;
  };

  /**
   * Returns Language Repository
   * @param {String} languageCode
   * @param {String} language
   * @returns {String}
   */
  const getLanguageRepository = (languageCode, language = "fr") => {
    console.log("[AuthProvider] getLanguageRepository", languageCode, language);
    const { repositories } = state;
    const languageRepository =
      typeof repositories.languages[languageCode] !== "undefined"
        ? repositories.languages[languageCode]
        : null;

    if (languageRepository) {
      return typeof languageRepository[language] !== "undefined"
        ? languageRepository[language]
        : null;
    }
    return null;
  };

  /**
   * Returns LanguageLevel Repository
   * @param {String} languageLevelCode
   * @param {String} language
   * @returns {String}
   */
  const getLanguageLevelRepository = (languageLevelCode, language = "fr") => {
    console.log(
      "[AuthProvider] getLanguageLevelRepository",
      languageLevelCode,
      language
    );
    const { repositories } = state;
    const languageLevelRepository =
      typeof repositories.languageLevels[languageLevelCode] !== "undefined"
        ? repositories.languageLevels[languageLevelCode]
        : null;

    if (languageLevelRepository) {
      return typeof languageLevelRepository[language] !== "undefined"
        ? languageLevelRepository[language]
        : null;
    }
    return null;
  };

  return (
    <SearchJobContext.Provider
      value={{
        ...state,
        initCurrentJobOffer,
        updateCurrentJobOffer,
        currentJobOffer,

        initCurrentFilters,
        updateCurrentFilters,
        currentFilters,

        initCurrentSearchResults,
        updateCurrentSearchResults,
        currentSearchResults,

        setStoredRepositories,
        removeStoredRepositories,
        getStoredRepositories,

        currentView,
        setCurrentView,

        currentGeoLocation,
        setCurrentGeoLocation,
        initCurrentGeoLocation,

        getServiceRepository,
        getLanguageRepository,
        getLanguageLevelRepository,
      }}
    >
      {children}
    </SearchJobContext.Provider>
  );
}

export { SearchJobContext, SearchJobProvider };
