// import { getFilters } from "./filters/filters.helpers";

/**
 * Returns User property
 * @param {Object} user
 * @param {String} property
 * @returns {Object}
 */
const getUserProperty = (user, property) => {
  if (user) {
    return typeof user[property] !== "undefined" ? user[property] : null;
  }
  return null;
};

/**
 * Returns User filter
 * @param {Object} userFilters
 * @param {String} filterName
 * @param {String} filterComponent
 * @returns {Array}
 */
const getUserFilter = (userFilters, filterName, filterComponent = null) => {
  // console.log("[getUserFilter]", userFilters, filterName, filterComponent);
  if (userFilters) {
    const userFilter =
      typeof userFilters[filterName] !== "undefined"
        ? userFilters[filterName]
        : null;

    if (userFilter && filterComponent) {
      switch (filterComponent) {
        case "MultiRange":
          return userFilter.map((item) => {
            return item.label;
          });

        case "RangeSlider":
          return {
            start: userFilter[0],
            end: userFilter[1],
          };

        case "SingleRange":
          return userFilter.label;

        case "GeoDistance":
          return userFilter.location;

        default:
          return null;
      }
    }
    return userFilter;
  }
  return null;
};

/**
 * Returns user filters queries (AppBase API)
 * @param {Object} userFilters
 * @param {Array} includedFilterIds
 * @returns {Array}
 */
const getReactiveBaseUserFiltersQueries = (
  userFilters,
  includedFilterIds = []
) => {
  const AUTHORIZED_USER_FILTERS = [
    {
      id: "job-offer-types",
      dataField: "type",
      queryFormat: "or",
    },
    {
      id: "languages-levels",
      dataField: "languages_levels",
      queryFormat: "or",
    },
    {
      id: "childcare-skills",
      dataField: "childcare_skills",
      queryFormat: "or",
    },

    {
      id: "job-offer-ids",
      dataField: "id",
      queryFormat: "or",
    },
  ];

  const filteredAuthorizedUserFilters = AUTHORIZED_USER_FILTERS.filter(
    (filter) => {
      return includedFilterIds.includes(filter.id);
    }
  );

  let userFiltersQueries = filteredAuthorizedUserFilters.map((filter) => {
    return {
      id: filter.id,
      type: "term",
      dataField: [filter.dataField],
      execute: false,
      // react: { // NB: useless
      //   and: getFilters([filter.id]),
      // },
      // size: 10,
      queryFormat: filter.queryFormat,
      // sortBy: "asc",
      value: getUserFilter(userFilters, filter.id),
    };
  });

  return userFiltersQueries;
};

/**
 * -- Offers
 */

/**
 * Returns true if jobOffer is applied
 * @param {Object} jobOffers
 * @param {Number} jobOfferId
 * @returns {Boolean}
 */
const isAppliedJobOffer = (jobOffers, jobOfferId) => {
  return jobOffers?.applications?.find((jobOffer) => {
    return Number(jobOffer.requestId) === Number(jobOfferId);
  });
};

/**
 * Returns true if jobOffer is bookmarked
 * @param {Object} jobOffers
 * @param {Number} jobOfferId
 * @returns {Boolean}
 */
const isBookmarkedJobOffer = (jobOffers, jobOfferId) => {
  return jobOffers?.bookmarks?.find((jobOffer) => {
    return Number(jobOffer.requestId) === Number(jobOfferId);
  });
};

/**
 * Returns true if jobOffer is deleted from suggested offers
 * @param {Object} jobOffers
 * @param {Number} jobOfferId
 * @returns {Boolean}
 */
const isDeletedJobOffer = (jobOffers, jobOfferId) => {
  return jobOffers?.deletions?.find((jobOffer) => {
    return Number(jobOffer.requestId) === Number(jobOfferId);
  });
};

/**
 * Returns jobOffer className
 * @param {String} defaultClassName
 * @param {Object} jobOffers
 * @param {Number} jobOfferId
 * @returns {String}
 */
const getJobOfferClassName = (
  defaultClassName = "offer",
  jobOffers,
  jobOfferId
) => {
  let className = defaultClassName;

  if (isBookmarkedJobOffer(jobOffers, jobOfferId)) {
    className = `${className} bookmarked`;
  }
  if (isAppliedJobOffer(jobOffers, jobOfferId)) {
    className = `${className} applied`;
  }
  if (isDeletedJobOffer(jobOffers, jobOfferId)) {
    className = `${className} deleted`;
  }
  return className;
};

/**
 * Returns string to describe children ages in an offer
 * @param {Array} childrens
 * @param {Function} t
 * @returns {String}
 */
const getChildrenAges = (children, t) => {
  return children.map((age, index) => {
    if (index === children.length - 1)
      return `${age} ${t("job-offer.yearsOld")}`;
    else if (index === children.length - 2)
      return `${age} ${t("job-offer.and")} `;
    else return `${age}, `;
  });
};

export {
  getUserFilter,
  getUserProperty,
  getJobOfferClassName,
  getChildrenAges,
  getReactiveBaseUserFiltersQueries,
};
