import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import LoadingButton from "@mui/lab/LoadingButton";

import { dateToString } from "../contracts/helper-contract";
import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import useAuth from "../../hooks/useAuth";

const CHOICES = {
  NO_CHANGE: "nochange",
  CHANGE_DATE: "change_date",
  CHANGE_TO_NO_DATE: "nodepart",
};

const DisponibilityModule = (props) => {
  const { t, i18n } = useTranslation();
  const { setAppUserBlockingActions, employeeConnector } = useAuth();
  const navigate = useNavigate();
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newDate, setNewDate] = useState(null);
  const [choice, setChoice] = useState(CHOICES.NO_CHANGE);

  const { language } = i18n;

  /**
   * Handle choice changing
   * @param {Object} e
   * @returns {void}
   */
  const handleChoiceChange = (e) => {
    const { value } = e.target;
    setChoice(value);
  };

  /**
   * Handle choice changing
   * @param {Object} e
   * @returns {void}
   */
  const handleDateChange = (value) => {
    setNewDate(value);
  };

  /**
   * Handle choice submiting
   * @returns {void}
   */
  const handleChoiceValidate = async () => {
    setLoading(true);
    const data = {
      change_reason: choice,
      change_date:
        choice === CHOICES.CHANGE_DATE && newDate ? dateToString(newDate) : "",
    };

    console.log("[DisponibilityModule] handleChoiceValidate", data);

    try {
      const result = await employeeConnector.setEndDate(data);
      if (result) {
        try {
          const beforeModules = await employeeConnector.getBeforeModules();
          const employeeState = {
            blockingActions: beforeModules.data.results.before_modules,
            notifications: beforeModules.data.results.message_notification,
          };
          setAppUserBlockingActions(employeeState);
          setLoading(false);
          navigate(`/`);
        } catch (error) {
          alert.error({ message: parseResponseErrorMessage(error) });
          setLoading(false);
          console.error("[DisponibilityModule] handleChoiceValidate", error);
        }
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[DisponibilityModule] handleChoiceValidate", error);
    }
  };

  /**
   * Get current user end date
   * @returns {void}
   */
  const getEndDate = useCallback(async () => {
    setLoading(true);
    try {
      const result = await employeeConnector.getEndDate();
      if (result) {
        setDate(result.data.results.depart_date);
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[DisponibilityModule] getEndDate", error);
    }
  }, [employeeConnector]);

  useEffect(() => {
    if (date === null && !loading && employeeConnector) {
      getEndDate();
    }
  }, [employeeConnector, date, loading, getEndDate]);

  return (
    <Box>
      <Card>
        <CardContent>
          <Typography variant="h3">
            {t("mandatory.disponibility.title")}
          </Typography>
          <Typography mt="1em">
            {date
              ? `${t("mandatory.disponibility.description")} : ${date}`
              : t("mandatory.disponibility.description-no-date")}
          </Typography>

          <Typography mt="1em">
            {t("mandatory.disponibility.question")}
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={choice}
              onChange={handleChoiceChange}
            >
              <FormControlLabel
                value={CHOICES.NO_CHANGE}
                control={<Radio />}
                label={t("mandatory.disponibility.yes")}
              />
              {date ? (
                <FormControlLabel
                  value={CHOICES.CHANGE_TO_NO_DATE}
                  control={<Radio />}
                  label={t("mandatory.disponibility.no-date")}
                />
              ) : null}
              <FormControlLabel
                value={CHOICES.CHANGE_DATE}
                control={<Radio />}
                label={t("mandatory.disponibility.no")}
              />
            </RadioGroup>
            <DatePicker
              id="change_date"
              name="change_date"
              disablePast
              disabled={choice !== CHOICES.CHANGE_DATE}
              value={newDate}
              inputFormat={language === "fr-FR" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
          <Box mt="1em">
            <LoadingButton
              disabled={!choice || (choice === CHOICES.CHANGE_DATE && !newDate)}
              variant="contained"
              onClick={handleChoiceValidate}
              loading={loading}
            >
              {t("mandatory.disponibility.validate")}
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DisponibilityModule;
