import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";

import {
  Alert as MuiAlert,
  TextField as MuiTextField,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";
import { getTokenFromURLParams } from "../../utils/sam.api";
import { PASSWORD_REGEX } from "../../constants";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ResetPassword() {
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const { t } = useTranslation();

  const { token } = getTokenFromURLParams();
  const [successMessage, setSuccessMessage] = useState(null);

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
        token: token ? token : "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .required(t("auth.required"))
          .matches(PASSWORD_REGEX, t("auth.bad-password-format")),
        confirmPassword: Yup.string().when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            t("auth.reset-password-both-same")
          ),
          token: Yup.string().required(t("auth.required")),
        }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const response = await resetPassword(
            values.password,
            values.confirmPassword,
            values.token
          );
          console.log("[ForgotPassword] response", response);

          setSuccessMessage(response.message);

          // redirect to sign in
          setTimeout(() => {
            navigate(`/auth/sign-in`);
          }, 3000);
        } catch (error) {
          const errorMessage = error?.message || t("auth.errors.global");

          setStatus({ success: false });
          setErrors({ submit: errorMessage });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {successMessage && (
            <Alert mt={2} mb={1} severity="success">
              {successMessage}
            </Alert>
          )}

          {!successMessage && (
            <>
              <TextField
                type="password"
                name="password"
                label={t("auth.reset-password-new")}
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <TextField
                type="password"
                name="confirmPassword"
                label={t("auth.reset-password-new-confirm")}
                value={values.confirmPassword}
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                fullWidth
                helperText={touched.confirmPassword && errors.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <input type="hidden" name="token" value={values.token} />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                size="large"
                className="auth-button"
              >
                {t("auth.reset-password-submit")}
              </LoadingButton>
            </>
          )}

          <Button
            component={Link}
            to={`/auth/sign-in`}
            fullWidth
            color="primary"
            size="large"
          >
            {t("auth.back-to-sign-in")}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
