import React from "react";

import { Helmet } from "react-helmet-async";

import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import PayslipsView from "./PayslipsView";

function Payslips() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("menu.payslips")} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <PayslipsView />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Payslips;
