import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Link, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { navMap } from "./constants";

const Todo = (props) => {
  const { step } = props;
  const navigate = useNavigate();

  /**
   * Handle click on link
   * @param {string} action
   * @returns {void}
   */
  const handleActionClick = (action) => {
    navigate(`/${navMap[action]}`);
  };

  /**
   * Render list of steps
   * @returns {Array}
   */
  const renderStep = () => {
    if (!step) return null;

    return (
      <Box mt="1.5em" ml="2em">
        <Box display="flex">
          <CheckCircleIcon
            color={step.valide ? "success" : "disabled"}
            fontSize="large"
          />
          <Typography pt="0.5em" ml="0.5em" variant="h6">
            {step.text}
          </Typography>
        </Box>
        {step.parcours && step.open
          ? step.parcours.map((action, i) => {
              return (
                <Box mt="0.5em" ml="2em" key={i}>
                  <Link
                    sx={{ cursor: "pointer" }}
                    display="flex"
                    onClick={() => handleActionClick(action.module)}
                  >
                    <CheckCircleIcon
                      color={action.valide ? "success" : "disabled"}
                      fontSize="large"
                    />
                    <Typography pt="0.5em" ml="0.5em">
                      {action.text}
                    </Typography>
                  </Link>
                </Box>
              );
            })
          : null}
      </Box>
    );
  };

  return <Box>{step ? renderStep() : null}</Box>;
};

export default Todo;
