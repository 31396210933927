import { format } from "date-fns";
import { enGB, fr } from "date-fns/locale";
import { appConfig } from "../config";

/**
 * Maps dashboardItems <> dashboardTranslations
 * @param {Object} dashboardItems
 * @param {Object} dashboardTranslations
 * @returns {Object}
 */
const mapTranslations = (dashboardItems, dashboardTranslations) => {
  // console.log("[mapTranslations]", dashboardItems, dashboardTranslations);

  const mappedTranslations = [...dashboardItems].map((dashboardItem) => {
    // page title
    if (
      dashboardItem.id &&
      typeof dashboardTranslations[dashboardItem.id] !== "undefined"
    ) {
      dashboardItem.title = dashboardTranslations[dashboardItem.id];
    }

    // pages title
    if (dashboardItem.pages) {
      dashboardItem.pages.forEach((page, index) => {
        if (page.id && typeof dashboardTranslations[page.id] !== "undefined") {
          dashboardItem.pages[index].title = dashboardTranslations[page.id];
        }

        // children pages title
        if (page.children) {
          page.children.forEach((child, childIndex) => {
            if (
              child.id &&
              typeof dashboardTranslations[child.id] !== "undefined"
            ) {
              dashboardItem.pages[index].children[childIndex].title =
                dashboardTranslations[child.id];
            }
          });
        }
      });
    }

    return dashboardItem;
  });

  return mappedTranslations;
};

/**
 * Returns translated Date
 * @param {Date} date
 * @param {String} locale
 * @param {String} formatStr
 * @returns {String}
 */
const translateDate = (date, locale, formatStr = "PP kk:mm") => {
  const locales = { enGB, fr };
  return format(date, formatStr, {
    locale: locales[locale],
  });
};

/**
 * Returns Offer Translated Prop
 * @param {Object} offer
 * @param {String} property
 * @param {String} language
 * @returns {String}
 */
const getOfferTranslatedProp = (offer, property, language) => {
  return typeof offer[`${property}_${language}`] !== "undefined"
    ? offer[`${property}_${language}`]
    : null;
};

/**
 * Returns UI Language from user language
 * @param {String} userLanguage
 * @returns {String}
 */
const getUILanguage = (userLanguage = appConfig.defaultLanguage) => {
  if (!appConfig.allowedLanguages.includes(userLanguage)) {
    return appConfig.UIDefaultLanguage;
  }

  const tmps = userLanguage.split("-");
  if (typeof tmps[0] === "undefined") {
    return appConfig.UIDefaultLanguage;
  }
  return tmps[0];
};

export {
  mapTranslations,
  translateDate,
  getOfferTranslatedProp,
  getUILanguage,
};
