import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CardContent,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import useAuth from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";
import { LANGUAGES } from "../../constants";

import Experiences from "../../components/profile/Experiences";
import UserInformations from "../../components/profile/UserInformations";
import SearchFiltersEmailAlert from "../../components/SearchJob/filters/SearchFiltersEmailAlert";

const cardSx = {
  padding: "1em",
  marginBottom: "1em",
};

function UserProfileView() {
  const { user, forgotPassword, setAppUserProfile, employeeConnector } =
    useAuth();
  const alert = useAlert();
  const { t, i18n } = useTranslation();
  const [communicationLanguage, setCommunicationLanguage] = useState(
    user?.language ? user.language : null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCommunicationLanguageChange = async (e) => {
    const { value } = e.target;
    setCommunicationLanguage(value);

    // we change the default UI language
    i18n.changeLanguage(value);

    const data = {
      language: value,
    };
    try {
      const response = await employeeConnector.updateLanguage(data);
      if (response) {
        setAppUserProfile(response);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[UserProfile] handleCommunicationLanguageChange", error);
    }
  };

  const handleResetPassword = async () => {
    // console.log("[UserProfile] handleResetPassword");
    try {
      setIsSubmitting(true);
      const response = await forgotPassword(user?.email);
      // console.log("[UserProfile] handleResetPassword - response", response);
      setIsSubmitting(false);

      alert.success({ message: response.message });
      //  alert.success({ message: t("alert.success.forgot-password") });
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
    }
  };

  const getLanguageOptions = () => {
    const options = [];
    for (const language in LANGUAGES) {
      options.push(
        <MenuItem key={language} value={LANGUAGES[language]}>
          {t(`user-profile.languages.${LANGUAGES[language]}`)}
        </MenuItem>
      );
    }
    return options;
  };

  return (
    user && (
      <>
        <Card sx={cardSx}>
          <CardContent>
            <UserInformations />
          </CardContent>
        </Card>
        <Card sx={cardSx}>
          <CardContent>
            <Experiences />
          </CardContent>
        </Card>
        <Card sx={cardSx}>
          <CardContent>
            <SearchFiltersEmailAlert
              selectedFilters={user?.emailAlert?.filters}
              emailAlert={user?.emailAlert}
              titleVariant="h4"
            />
          </CardContent>
        </Card>
        <Card sx={cardSx}>
          <CardContent>
            <Typography variant="h4" mb="1em">
              {t("user-profile.communicationLanguage")}
            </Typography>
            <Typography mb="1em">
              {t("user-profile.changeCommunicationLanguage")}
            </Typography>
            <Select
              onChange={handleCommunicationLanguageChange}
              value={
                communicationLanguage ? communicationLanguage : user.language
              }
            >
              {getLanguageOptions()}
            </Select>
          </CardContent>
        </Card>
        <Card sx={cardSx}>
          <CardContent>
            <Typography mb="1em" variant="h4">
              {t("user-profile.password")}
            </Typography>
            <Typography mb="2em">{t("user-profile.resetPassword")}</Typography>
            <Box display="grid" justifyContent="center">
              <LoadingButton
                variant="contained"
                width="fit-content"
                size="large"
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleResetPassword}
              >
                {t("user-profile.resetMyPassword")}
              </LoadingButton>
            </Box>
          </CardContent>
        </Card>
      </>
    )
  );
}

export default UserProfileView;
