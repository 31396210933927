/**
 * Returns true if date within Range
 * @param {Date} date
 * @param {Date} startRange
 * @param {Date} endRange
 * @returns {boolean}
 */
export const isDateWithinRange = (date, startRange, endRange) => {
  // console.log(
  //   "[isDateWithinRange]:",
  //   startRange,
  //   date,
  //   endRange,
  //   new Date(startRange).getTime(),
  //   new Date(date).getTime(),
  //   new Date(endRange).getTime()
  // );
  return (
    new Date(date).getTime() >= new Date(startRange).getTime() &&
    new Date(date).getTime() <= new Date(endRange).getTime()
  );
};

/**
 * Format date
 * @param {String} date
 * @param {String} language
 * @returns {void}
 */
export const formatDate = (date, language) => {
  if (!date) {
    return null;
  }
  if (language === "fr") {
    const [Y, m, d] = date.split("-");
    return `${d}/${m}/${Y}`;
  }
  // default EN date
  return date;
};
