import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";

import DateEndChangeFrom from "../contracts/DateEndChangeForm";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";
import { isMobileDevice } from "../../helpers/common.helpers";

const ContractDisponibilityModule = (props) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { setAppUserBlockingActions, employeeConnector } = useAuth();
  const [contracts, setContracts] = useState(null);
  const [dateChangeReasons, setDateChangeReasons] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [changeDatecontract, setChangeDateContract] = useState(null);

  /**
   * Update list contract to display current status of contracts
   * @returns {void}
   */
  const updateList = useCallback(
    (index, reason, date) => {
      const contract = {
        ...contracts[index],
        my_end_reason: reason,
        my_end_date: date ? date : contracts[index].my_end_date,
        resolved: true,
      };
      contracts[index] = contract;
      setContracts([...contracts]);
    },
    [contracts]
  );

  /**
   * Check if all contract have been treated
   * @returns {void}
   */
  const checkAllResovled = useCallback(async () => {
    setLoading(true);
    let allResolved = true;

    contracts.forEach((contract) => {
      if (!contract.resolved) allResolved = false;
    });
    console.log(
      "[ContractDisponibilityModule] checkAllResovled all is resolved",
      allResolved
    );

    if (!allResolved) {
      setLoading(false);
      return;
    } else {
      try {
        const beforeModules = await employeeConnector.getBeforeModules();
        const employeeState = {
          blockingActions: beforeModules.data.results.before_modules,
          notifications: beforeModules.data.results.message_notification,
        };
        setAppUserBlockingActions(employeeState);
        setLoading(false);
        navigate(`/`);
      } catch (error) {
        alert.error({ message: parseResponseErrorMessage(error) });
        setLoading(false);
        console.error("[DisponibilityModule] handleChoiceValidate", error);
      }
    }
  }, [
    contracts,
    alert,
    employeeConnector,
    navigate,
    setAppUserBlockingActions,
  ]);

  /**
   * Handle changing of end date for one contract
   * @returns {void}
   */
  const handleChangeEndDateSubmit = async (values) => {
    console.log(
      "[ContractDisponibilityModule] handleChangeDate",
      values,
      contracts[changeDatecontract]
    );

    setOpen(false);
    setLoading(true);
    try {
      const result = await employeeConnector.setOfferEndDate(
        contracts[changeDatecontract].offer_id,
        values
      );
      if (result) {
        updateList(
          changeDatecontract,
          values.change_reason,
          values.change_date
        );
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[ContractDisponibilityModule] handleConfirmDate", error);
    }
  };

  /**
   * Handle date changing form open for one contract
   * @returns {void}
   */
  const handleChangeDate = (index) => {
    console.log("[ContractDisponibilityModule] handleChangeDate", index);
    setChangeDateContract(index);
    setOpen(true);
  };

  /**
   * Handle confirmation of end date for one contract
   * @returns {void}
   */
  const handleConfirmDate = useCallback(
    async (contract, index) => {
      console.log("[ContractDisponibilityModule] handleConfirmDate", index);

      setLoading(true);
      try {
        const result = await employeeConnector.confirmOfferEndDate(
          contract.offer_id
        );
        if (result) {
          updateList(index, "confrim");
          setLoading(false);
        }
      } catch (error) {
        alert.error({ message: parseResponseErrorMessage(error) });
        setLoading(false);
        console.error("[ContractDisponibilityModule] handleConfirmDate", error);
      }
    },
    [employeeConnector, alert, updateList]
  );

  /**
   * Get contract list of user that need date confirmation
   * @returns {void}
   */
  const getContracts = useCallback(async () => {
    console.log("[ContractDisponibilityModule] getContracts");

    setLoading(true);
    try {
      const result = await employeeConnector.getOffersDateChange();
      if (result) {
        setDateChangeReasons(result.data.results.reasons);
        setContracts(result.data.results.offres);
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[ContractDisponibilityModule] getContracts", error);
    }
  }, [employeeConnector, alert]);

  /**
   * Render action buttons or contract status saved message
   * @param {Object} contract
   * @param {Integer} index
   * @returns {Object}
   */
  const renderContractStatus = (contract, index) => {
    if (loading)
      return (
        <Box mt="0.5em">
          <CircularProgress size="1.125em" />
        </Box>
      );

    if (contract.resolved)
      return (
        <Box mt="1em" display="flex">
          <CheckCircleIcon color="success" fontSize="large" />
          <Typography sx={{ paddingTop: "0.5em" }} color="success">
            {t("mandatory.contract-disponibility.choice-saved")}
          </Typography>
        </Box>
      );
    else
      return (
        <Box mt="1em" sx={{ display: fullScreen ? "inline-grid " : "flex" }}>
          <Button
            onClick={() => handleChangeDate(index)}
            variant="contained"
            color="error"
          >
            {t("mandatory.contract-disponibility.change-date")}
          </Button>
          <Button
            sx={{ margin: fullScreen ? "1em 0 0 0" : "0 0 0 1em" }}
            onClick={() => handleConfirmDate(contract, index)}
            variant="contained"
          >
            {t("mandatory.contract-disponibility.confirm-date")}
          </Button>
        </Box>
      );
  };

  /**
   * Render contract list of user that need date confirmation
   * @returns {Array}
   */
  const renderContractList = () => {
    if (!contracts || contracts.length === 0) return null;

    return contracts.map((contract, index) => {
      return (
        <Box key={index} mb="1em">
          <Card>
            <CardContent>
              <Typography mb="1em">{contract.customer_name}</Typography>
              <Divider />
              <Typography mt="1em">
                {t("mandatory.contract-disponibility.start-on")}{" "}
                {contract.start_date}
              </Typography>
              <Typography mt="1em">
                {t("mandatory.contract-disponibility.end-on")}{" "}
                {contract.end_date}
              </Typography>
              <Typography mt="1em">
                {t("mandatory.contract-disponibility.my-end-date")}{" "}
                {contract.my_end_date}
              </Typography>
              {renderContractStatus(contract, index)}
            </CardContent>
          </Card>
        </Box>
      );
    });
  };

  /**
   * Render date change dialog for desktop mode
   * @returns {Object}
   */
  const renderDateChangeDialog = () => {
    return (
      <Dialog open={open} onClose={() => setOpen(!open)}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4">
              {t("contract.date-change.title")}
            </Typography>
            <IconButton pt="0" onClick={() => setOpen(!open)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DateEndChangeFrom
            onSubmit={handleChangeEndDateSubmit}
            reasons={dateChangeReasons}
          />
        </DialogContent>
      </Dialog>
    );
  };

  /**
   * Render date change dialog for mobile mode
   * @returns {Object}
   */
  const renderDateChangeDrawer = () => {
    return (
      <Drawer anchor="bottom" open={open} onClose={() => setOpen(!open)}>
        <Box m="1em">
          <Box display="flex" justifyContent="space-between">
            <Typography ml="20%" variant="h4">
              {t("contract.date-change.title")}
            </Typography>
            <IconButton pt="0" onClick={() => setOpen(!open)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <DateEndChangeFrom
              onSubmit={handleChangeEndDateSubmit}
              reasons={dateChangeReasons}
            />
          </Box>
        </Box>
      </Drawer>
    );
  };

  useEffect(() => {
    if (contracts === null && !loading && employeeConnector) {
      getContracts();
    }
    if (contracts && contracts.length && !loading && employeeConnector)
      checkAllResovled();
  }, [employeeConnector, contracts, loading, getContracts, checkAllResovled]);

  return (
    <Box>
      <Typography variant="h3" className="view-title">
        {t("mandatory.contract-disponibility.title")}
      </Typography>
      <Box mt="1em">
        <Typography>
          {t("mandatory.contract-disponibility.description")}
        </Typography>
        {loading && !contracts ? (
          <Box display="grid" justifyContent="center" mt="1em">
            <CircularProgress />
          </Box>
        ) : null}
        <Box mt="1em">{renderContractList()}</Box>
      </Box>
      {isMobileDevice() ? renderDateChangeDrawer() : renderDateChangeDialog()}
    </Box>
  );
};

export default ContractDisponibilityModule;
