import { LogglyTracker } from "loggly-jslogger";

import { appConfig } from "../config";

/**
 * Logger config
 * https://github.com/loggly/loggly-jslogger
 */
const Logger = new LogglyTracker();

Logger.push({
  logglyKey: appConfig.loggly.token,
  tag: appConfig.loggly.tag,
  sendConsoleErrors: true,
  useUtfEncoding: true,
});

export default Logger;
