import { appConfig } from "../config";

/**
 * Returns error logs
 * @param {String} error
 * @param {String} method
 * @returns {String}
 */
const logError = (error, method) => {
  if (error.response) {
    console.error(`[${method}] Error response data:`, error.response.data);
    console.error(`[${method}] Error response status:`, error.response.status);
    console.error(
      `[${method}] Error response headers:`,
      error.response.headers
    );
  } else if (error.request) {
    console.error(`[${method}] Error request:`, error.request);
  } else {
    console.error(`[${method}] Error message:`, error.message);
  }
  console.error(`[${method}] Error config:`, error.config);
};

/**
 * Returns XHR Config headers, credentials ...
 * @param {Object} token
 * @param {String} language
 * @returns {Object}
 */
// const getApiXHRConfig = (token, language = appConfig.defaultLanguage) => {
//   if (!token || !token.type || !token.value) {
//     throw new Error("[getApiXHRConfig] undefined token ...");
//   }
//   const config = {
//     headers: {
//       "Content-type": "application/json",
//       "x-language": language,
//     },
//     withCredentials: true,
//     crossDomain: true,
//   };
//   config.headers[token.type] = token.value;

//   return config;
// };

/**
 * Returns XHR Config headers
 * @param {Object} additionalHeaders
 * @param {String} language
 * @returns {Object}
 */
const getSamProxyXHRConfig = (
  additionalHeaders = {},
  language = appConfig.defaultLanguage
) => {
  const config = {
    headers: {
      ...additionalHeaders,
      "Content-type": "application/json",
      language: language,
      cachePolicy: "no-cache",
    },
    withCredentials: true,
    crossDomain: true,
  };

  return config;
};

export {
  logError,
  // getApiXHRConfig,
  getSamProxyXHRConfig,
};
