import { amber, green, red, blue } from "@mui/material/colors";

// Themes
export const THEMES = {
  MOMJI: "MOMJI",
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const VIEW_MODES = {
  TABLE: "table",
  EDIT: "edit",
  CREATE: "create",
};

export const FIELD_TYPES = {
  INPUT: "input",
  TEXT: "text",
  SELECT: "select",
  ASYNC_SELECT: "asyncSelect",
  SWITCH: "switch",
  DATE: "date",
  DATA_GRID: "dataGrid",
};

/*
 * Field sizes
 */

export const FIELD_SIZES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

/*
 * Actions
 */
export const ACTION_TYPES = {
  READ: "read",
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
  PATCH: "replace",
};

export const HTTP_METHOD_TYPES = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const USER_TYPES = {
  SPEAKER: "speaker",
  CUSTOMER: "customer",
  ADMINISTRATOR: "administrator",
};

export const DEFAULT_STYLES = {
  PAPER_BORDER_RADIUS: "4px",
  PAPER_BOX_SHADOW:
    "rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px",

  FIELD_MARGIN: "8px 0",
};

export const LOCALSTORAGE_ITEM_KEYS = {
  XSRF: "xsrfTokens",
  USER: "user",
  REPOSITORIES: "repositories",
};

export const ALLOWED_EXTERNAL_ENV = ["sam"]; // ['sam']

export const LANGUAGES = {
  FR: "fr-FR",
  EN: "en-GB",
};

export const JOB_OFFERS_STATUS = {
  application: {
    color: amber[50],
    textColor: amber[700],
  },
  canceled: {
    color: red[100],
    textColor: red[800],
  },
  rejected: {
    color: red[100],
    textColor: red[800],
  },
  meeting: {
    color: blue[50],
    textColor: blue[700],
  },
  contract: {
    color: green[50],
    textColor: green[600],
  },
  transmission: {
    color: amber[50],
    textColor: amber[700],
  },
};

/**
 * Password Regex
 * Must Contain :
 * - 8 Characters,
 * - One Uppercase,
 * - One Lowercase,
 * - One Number
 */
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

/**
 * ReactiveGoogleMap default config
 * @returns {Object}
 */
export const MAP_DEFAULT_CONFIG = {
  center: { lat: 48.864716, lng: 2.349014 }, // Paris, FR
  zoom: 6,
};
