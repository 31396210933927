import React from "react";
import {
  MultiDataList,
  // SingleDropdownRange,
  ReactiveComponent,
} from "@appbaseio/reactivesearch";

import { useTranslation } from "react-i18next";

import { getUserFilter } from "../user.helpers";
import { getFilters, getMultiListCustomItem } from "./filters.helpers";
import { showError } from "../search.helpers";

/**
 * Returns AvailabilitiesByDaysAndTimePeriods
 * @param {Object} props
 * @returns {ReactComponent}
 */
export default function AvailabilitiesByDaysAndTimePeriods(props) {
  const { mergedFilters, repositories, language } = props;
  const { t } = useTranslation();

  const componentId = "availabilities";

  //   const getListItems = (data, rawData, value, handleChange) => {
  // console.log(
  //   "[AvailabilitiesByDaysAndTimePeriods] - getListItems",
  //   data,
  //   rawData,
  //   value,
  //   handleChange
  // );

  //     if (!data) {
  //       return null;
  //     }

  //     return (
  //       <ul role="listbox" aria-label={`${componentId}-items`}>
  //         {data.map((item) => {
  //           console.log(
  //             "[AvailabilitiesByDaysAndTimePeriods] - getListItems - item:",
  //             item,
  //             item.key,
  //             item.isSelected
  //           );
  //           return (
  //             <li
  //               role="option"
  //               aria-checked={item.isSelected}
  //               aria-selected={item.isSelected}
  //             >
  //               <input
  //                 type="checkbox"
  //                 className="checkbox"
  //                 id={`${componentId}-${item.value}`}
  //                 name={`${componentId}-${item.value}`}
  //                 value={item.value}
  //                 onClick={(e) => handleChange(item.value)}
  //               />
  //               <label for={`${componentId}-${item.value}`}>
  //                 {getMultiListCustomItem(
  //                   item.label,
  //                   item.count,
  //                   item.isSelected,
  //                   "availabilities",
  //                   repositories,
  //                   language,
  //                   t
  //                 )}
  //               </label>
  //             </li>
  //           );
  //         })}
  //       </ul>
  //     );
  //   };

  /**
   * Returns DayTime Periods
   * @returns {Array}
   */
  //   const getDayTimePeriods = () => {
  //     return [
  //       { start: 1, end: 4, label: t("daytime-periods.all-day") },
  //       { start: 1, end: 1, label: t("daytime-periods.1") },
  //       { start: 2, end: 2, label: t("daytime-periods.2") },
  //       { start: 3, end: 3, label: t("daytime-periods.3") },
  //       { start: 4, end: 4, label: t("daytime-periods.4") },
  //     ];
  //   };

  /**
   * Returns DayTime Start Periods
   * @returns {Array}
   */
  //   const getDayStartTimePeriods = () => {
  //     return [
  //       { start: 1, end: 1, label: t("daytime-start-periods.1") },
  //       { start: 2, end: 2, label: t("daytime-start-periods.2") },
  //       { start: 3, end: 3, label: t("daytime-start-periods.3") },
  //       { start: 4, end: 4, label: t("daytime-start-periods.4") },
  //     ];
  //   };

  /**
   * Returns Day start times
   * @returns {Array}
   */
  const getDayStartTimes = () => {
    const START_TIME = 700; // 07:00
    const END_TIME = 2000; // 20:00
    const STEP = 30; // 30 minuts

    const dayStartTimes = [];

    // first entry
    dayStartTimes.push({
      start: null,
      end: null,
      label: "",
    });

    for (let time = START_TIME; time <= END_TIME; time = time + STEP) {
      // round to next hour
      if (/60$/gm.test(time)) {
        time += 40;
      }

      dayStartTimes.push({
        start: time,
        end: 2359, // end of the day
        label: formatDayStartTime(time),
      });
    }
    return dayStartTimes;
  };

  /**
   * Returns formated Day start time (ex: 2000 > 20h00)
   * @param {Number} _time
   * @param {String} delimitor
   * @returns {Object}
   */
  const formatDayStartTime = (_time, delimitor = "h") => {
    const time = _time.toString();
    const minuts = time.substring(time.length - 2);
    const hours =
      time.length === 3 ? time.substring(0, 1) : time.substring(0, 2);
    return `${hours.padStart(2, "0")}${delimitor}${minuts}`;
  };

  /**
   * Returns DayTimePeriodsComponentInfos
   * @param {String} label
   * @returns {Array}
   */
  const getDayTimePeriodsComponentInfos = (label) => {
    let filterId;
    let componentId;

    switch (label.trim().toLowerCase()) {
      case "lundi":
      case "monday":
        filterId = "monday_from";
        componentId = "monday-from";
        break;

      case "mardi":
      case "tuesday":
        filterId = "tuesday_from";
        componentId = "tuesday-from";
        break;

      case "mercredi":
      case "wednesday":
        filterId = "wednesday_from";
        componentId = "wednesday-from";
        break;

      case "jeudi":
      case "thursday":
        filterId = "thursday_from";
        componentId = "thursday-from";
        break;

      case "vendredi":
      case "friday":
        filterId = "friday_from";
        componentId = "friday-from";
        break;

      case "samedi":
      case "saturday":
        filterId = "saturday_from";
        componentId = "saturday-from";
        break;

      // case "dimanche":
      // case "sunday":
      //   filterId = "sunday_from";
      //   componentId = "sunday-from";
      //   break;

      default:
        filterId = "";
    }
    return {
      filterId,
      componentId,
    };
  };

  //   const __getDayTimePeriodsRangeComponent = (label, isSelected) => {
  //     // console.log(
  //     //   "[AvailabilitiesByDaysAndTimePeriods] getDayTimePeriodsRangeComponent - label",
  //     //   label,
  //     //   isSelected
  //     // );
  //     const data = getDayTimePeriods();

  //     const { filterId, componentId } = getDayTimePeriodsComponentInfos(label);

  //     return (
  //       <div className="time-periods">
  //         <label for={filterId}>A partir de</label>
  //         <select
  //           name={filterId}
  //           id={filterId}
  //           disabled={!isSelected}
  //           onChange={(e) => {
  //             console.log("onChange", filterId, e.target.value);

  //             // TODO : set user filters ? (getReactiveBaseUserFiltersQueries)
  //           }}
  //         >
  //           {data.map((d) => {
  //             return <option value={d.start}>{d.label}</option>;
  //           })}
  //         </select>
  //       </div>
  //     );
  //   };

  //   const __getDayTimePeriodsRangeComponent = (label, isSelected) => {
  //     const { filterId, componentId } = getDayTimePeriodsComponentInfos(label);

  //     return (
  //       <SingleDropdownRange
  //         componentId={componentId}
  //         dataField={filterId}
  //         data={getDayTimePeriods()}
  //         title="A partir de"
  //         // defaultValue={getUserFilter(
  //         //   mergedFilters,
  //         //   "monday-time-periods",
  //         //   "MultiRange"
  //         // )}
  //         react={{ and: getFilters([componentId]) }}
  //       />
  //     );
  //   };

  /**
   * Returns WeekDays Data
   * @returns {Array}
   */
  const getWeekDaysData = () => {
    return [
      {
        label: t("week-days.monday"),
        value: "MONDAY",
      },
      {
        label: t("week-days.tuesday"),
        value: "TUESDAY",
      },
      {
        label: t("week-days.wednesday"),
        value: "WEDNESDAY",
      },
      {
        label: t("week-days.thursday"),
        value: "THURSDAY",
      },
      {
        label: t("week-days.friday"),
        value: "FRIDAY",
      },
      {
        label: t("week-days.saturday"),
        value: "SATURDAY",
      },
      // {
      //   label: t("week-days.sunday"),
      //   value: "SUNDAY",
      // },
    ];
  };

  /**
   * Returns Array values ToUpperCase
   * @param {Array} values
   * @returns {Array}
   */
  const mapValuesToUpperCase = (values) => {
    if (!Array.isArray(values)) {
      return [];
    }
    return values.map((value) => {
      return value.toUpperCase();
    });
  };

  /**
   * Returns all excluded availabilities from query
   * @param {Array} selectedDays
   * @returns {Array}
   */
  const getExcludedAvailabilities = (selectedDays) => {
    if (!Array.isArray(selectedDays)) {
      return [];
    }
    if (!selectedDays.length) {
      // NB : no selection
      return [];
    }
    const weekDays = getWeekDaysData();

    return weekDays
      .filter((weekDay) => {
        return !selectedDays.includes(weekDay.value);
      })
      .map((weekDay) => {
        return weekDay.value;
      });
  };

  /**
   * Returns CustomQuery
   * NB: customQuery is called on value changes in the MultiDataList !
   * To update / remove values, click on checkboxes is necessary ...
   * @param {Array} selectedAvailabilities
   * @returns {Object}
   */
  const getCustomQuery = (selectedAvailabilities) => {
    if (
      // NB : no selection
      !selectedAvailabilities ||
      (Array.isArray(selectedAvailabilities) && !selectedAvailabilities.length)
    ) {
      console.log(
        "[AvailabilitiesByDaysAndTimePeriods] - getCustomQuery  no selection",
        selectedAvailabilities
      );
      return {};
    }

    console.log(
      "[AvailabilitiesByDaysAndTimePeriods] - getCustomQuery  OK",
      selectedAvailabilities
    );

    return {
      query: {
        bool: {
          must: [
            {
              terms: {
                availabilities: mapValuesToUpperCase(selectedAvailabilities),
              },
            },
            {
              bool: {
                must_not: [
                  {
                    terms: {
                      availabilities: getExcludedAvailabilities(
                        mapValuesToUpperCase(selectedAvailabilities)
                      ),
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    };
  };

  /**
   * Returns DayTimePeriodsRange
   * @param {Object} label
   * @param {Boolean} isSelected
   * @returns {ReactComponent}
   */
  const getDayTimePeriodsRangeComponent = (label, isSelected) => {
    const { filterId, componentId } = getDayTimePeriodsComponentInfos(label);

    const data = getDayStartTimes();
    // const data = getDayStartTimePeriods();

    const selectedValue = getUserFilter(mergedFilters, componentId);
    // console.log(
    //   "[getDayTimePeriodsRangeComponent] selectedValue",
    //   componentId,
    //   selectedValue
    // );

    return (
      <ReactiveComponent
        componentId={componentId}
        dataField={filterId} // NB : not taken into account ...
        // aggregationField={filterId}
        index={filterId}
        filterLabel={t("search-job.time-from")}
        showFilter={true}
        defaultValue={selectedValue}
        react={{
          and: getFilters([componentId]),
        }}
        renderError={(error) => showError(componentId, error)}
        render={({ aggregations, setQuery }) => (
          <div className="time-periods">
            <label htmlFor={componentId}>{t("search-job.time-from")}</label>
            <select
              name={componentId}
              id={componentId}
              disabled={!isSelected}
              onChange={(e) => {
                console.log(
                  "[getDayTimePeriodsRangeComponent] onChange",
                  filterId,
                  e.target.value,
                  selectedValue
                );

                const term = {};
                term[filterId] = [Number(e.target.value)];

                const query = {
                  query: { term },
                };
                setQuery({
                  query,
                  // dataField: filterId, // KO
                  value: [Number(e.target.value)],
                });
              }}
            >
              {data.map((d) => {
                if (selectedValue && selectedValue[0] === d.start) {
                  return (
                    <option value={d.start} selected key={d.start}>
                      {d.label}
                    </option>
                  );
                }
                return (
                  <option value={d.start} key={d.start}>
                    {d.label}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        // onQueryChange={({ prevQuery, nextQuery }) => { // ??
        //   console.log(
        //     "[getDayTimePeriodsRangeComponent] onQueryChange",
        //     prevQuery,
        //     nextQuery
        //   );
        // }}
      />
    );
  };

  const selectedAvailabilities = getUserFilter(mergedFilters, "availabilities");
  console.log(
    "[AvailabilitiesByDaysAndTimePeriods] - selectedAvailabilities",
    selectedAvailabilities
  );

  return (
    <MultiDataList
      componentId={componentId}
      dataField="availabilities"
      className="filter availabilities-filter"
      title={t("week-days.title")}
      filterLabel={t("week-days.title")}
      showSearch={false}
      showCount={true}
      data={getWeekDaysData()}
      defaultValue={selectedAvailabilities}
      customQuery={() => {
        return getCustomQuery(selectedAvailabilities);
      }}
      renderItem={(label, count, isSelected) =>
        getMultiListCustomItem(
          label,
          count,
          isSelected,
          "availabilities",
          repositories,
          language,
          t,
          getDayTimePeriodsRangeComponent(label, isSelected),
          false
        )
      }
      react={{ and: getFilters(["availabilities"]) }}
    />
  );
}
