import React from "react";

import { Helmet } from "react-helmet-async";

import { Divider as MuiDivider, Grid, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

const Divider = styled(MuiDivider)(spacing);

function Resources() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet title={t("menu.resources")} />
      <Typography variant="h3" gutterBottom display="grid" textAlign="center">
        {t("menu.resources")}
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}></Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Resources;
