import React from "react";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material/";

import useSearchJob from "../../hooks/useSearchJob";

function Schedule(props) {
  const { offer } = props;
  const { i18n } = useTranslation();
  const { repositories } = useSearchJob();

  const language = i18n.language;

  const formatTime = (day_schedule) => {
    return day_schedule.replace(":", "h");
  };

  /**
   * Render list of scheduled days
   * @returns {Array}
   */
  const renderDays = () => {
    return (
      <>
        {offer.monday_schedule_1.length > 0 ? (
          <Typography>
            {repositories.availabilities.MONDAY[language]}
          </Typography>
        ) : null}

        {offer.tuesday_schedule_1.length > 0 ? (
          <Typography>
            {repositories.availabilities.TUESDAY[language]}
          </Typography>
        ) : null}

        {offer.wednesday_schedule_1.length > 0 ? (
          <Typography>
            {repositories.availabilities.WEDNESDAY[language]}
          </Typography>
        ) : null}

        {offer.thursday_schedule_1.length > 0 ? (
          <Typography>
            {repositories.availabilities.THURSDAY[language]}
          </Typography>
        ) : null}

        {offer.friday_schedule_1.length > 0 ? (
          <Typography>
            {repositories.availabilities.FRIDAY[language]}
          </Typography>
        ) : null}

        {offer.saturday_schedule_1.length > 0 ? (
          <Typography>
            {repositories.availabilities.SATURDAY[language]}
          </Typography>
        ) : null}

        {offer.sunday_schedule_1.length > 0 ? (
          <Typography>
            {repositories.availabilities.SUNDAY[language]}
          </Typography>
        ) : null}
      </>
    );
  };

  /**
   * Render list of scheduled hours
   * @returns {Array}
   */
  const renderHours = () => {
    return (
      <>
        {offer.monday_schedule_1.length > 1 ? (
          <Typography>
            {formatTime(offer.monday_schedule_1[0])} /{" "}
            {formatTime(offer.monday_schedule_1[1])}
          </Typography>
        ) : null}

        {offer.tuesday_schedule_1.length > 1 ? (
          <Typography>
            {formatTime(offer.tuesday_schedule_1[0])} /{" "}
            {formatTime(offer.tuesday_schedule_1[1])}
          </Typography>
        ) : null}

        {offer.wednesday_schedule_1.length > 1 ? (
          <Typography>
            {formatTime(offer.wednesday_schedule_1[0])} /{" "}
            {formatTime(offer.wednesday_schedule_1[1])}
          </Typography>
        ) : null}

        {offer.thursday_schedule_1.length > 1 ? (
          <Typography>
            {formatTime(offer.thursday_schedule_1[0])} /{" "}
            {formatTime(offer.thursday_schedule_1[1])}
          </Typography>
        ) : null}

        {offer.friday_schedule_1.length > 1 ? (
          <Typography>
            {formatTime(offer.friday_schedule_1[0])} /{" "}
            {formatTime(offer.friday_schedule_1[1])}
          </Typography>
        ) : null}

        {offer.saturday_schedule_1.length > 1 ? (
          <Typography>
            {formatTime(offer.saturday_schedule_1[0])} /{" "}
            {formatTime(offer.saturday_schedule_1[1])}
          </Typography>
        ) : null}

        {offer.sunday_schedule_1.length > 1 ? (
          <Typography>
            {formatTime(offer.sunday_schedule_1[0])} /{" "}
            {formatTime(offer.sunday_schedule_1[1])}
          </Typography>
        ) : null}
      </>
    );
  };

  return offer ? (
    <Grid width="100%" container display="flex" {...props}>
      <Grid item xs={6}>
        {renderDays()}
      </Grid>
      <Grid item xs={6} textAlign="end">
        {renderHours()}
      </Grid>
    </Grid>
  ) : null;
}

export default Schedule;
