import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import {
  getAutoConnectionInfosFromURLParams,
  mapModuleToAppRoute,
} from "../../utils/sam.api";

import { Alert as MuiAlert, Paper, Typography } from "@mui/material";
import { spacing } from "@mui/system";

import { ReactComponent as Logo } from "../../vendor/momji_logo_837x551.svg";

import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";
import { getStoredUser } from "../../helpers/auth.helpers";

const Alert = styled(MuiAlert)(spacing);

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 180px;
  height: 120px;
  margin-bottom: 20px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function AutoSignIn() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { autoSignIn, setAppUser, isAuthenticated } = useAuth();

  useEffect(() => {
    console.log("[AutoSignIn] useEffect - isAuthenticated:", isAuthenticated);

    // authenticate user
    const handleAuthenticate = async () => {
      try {
        const { key1, key2, module, request_id } =
          getAutoConnectionInfosFromURLParams();

        await autoSignIn(key1, key2, true);

        const user = getStoredUser(); // TODO : use state.user ...
        console.log("[AutoSignIn.handleAuthenticate] getStoredUser: ", user);

        if (user) {
          await setAppUser();

          if (module) {
            const route = mapModuleToAppRoute(module, request_id);
            navigate(`/${route}`);
            return;
          }
          navigate(`/`);
        }
      } catch (error) {
        console.error("[AutoSignIn.handleAuthenticate]", error);
        const errorMessage = error?.message || t("auth.errors.global");
        setError(errorMessage);
      }
    };
    handleAuthenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Helmet title={t("auth.welcome")} />

        {error && (
          <Alert mt={2} mb={3} severity="warning">
            {error}
          </Alert>
        )}
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {t("auth.auto-connect")}
        </Typography>
      </Wrapper>
    </React.Fragment>
  );
}

export default AutoSignIn;
