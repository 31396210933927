import React from "react";
import { SelectedFilters } from "@appbaseio/reactivesearch";

import { useTranslation } from "react-i18next";

import useSearchJob from "../../../hooks/useSearchJob";

/**
 * Returns SelectedFilters Section
 * @param {Object} props
 * @returns {ReactComponent}
 */
export default function SelectedFiltersSection(props) {
  const { t } = useTranslation();
  const { updateCurrentFilters, initCurrentGeoLocation } = useSearchJob();

  // console.log("[SelectedFiltersSection] props:", props);
  const { hideFilters, hideFilterLabel } = props;

  if (hideFilters) {
    return null;
  }

  /**
   * Format Selected Filters labels
   * @returns {void}
   */
  const formatSelectedFilters = () => {
    // console.log("[SelectedFilters] onChange - formatSelectedFilters");
    const elts = document.querySelectorAll("section.selected-filters a");

    if (elts.length) {
      elts.forEach((elt) => {
        // format : {time}_from
        const timeFromRegex = new RegExp(`${t("search-job.time-from")}`);

        if (timeFromRegex.test(elt.textContent)) {
          // console.log(
          //   "[SelectedFilters] onChange - formatSelectedFilters - timeFromRegex found : ",
          //   elt.textContent
          // );

          const captureRegex = /(\d{1,2})(\d{2})/gm;
          elt.innerHTML = elt.innerHTML.replace(captureRegex, "$1h$2");
        }

        if (hideFilterLabel) {
          // remove filter label to gain space ....

          // remove last x
          const str = elt.textContent.substring(0, elt.textContent.length - 1);

          // we only keep value
          const tmps = str.split(":");
          if (tmps.length === 2) {
            elt.innerHTML = `<span>${tmps[1]}</span><span>✕</span>`;
          }
        }
      });
    }
  };

  return (
    <section className="selected-filters">
      <SelectedFilters
        className="selected-filters-container"
        showClearAll={false}
        clearAllLabel={t("search-job.init-filters")}
        onChange={(filters) => {
          console.log("[SelectedFilters] onChange - filters:", filters);

          // remove undefined values ... (ex: monday-from, tuesday-from ...)
          for (const key of Object.keys(filters)) {
            if (typeof filters[key].value === "undefined") {
              console.log("[SelectedFilters] onChange removing entry :", key);
              delete filters[key];
            }
          }
          console.log(
            "[SelectedFilters] onChange (after cleaning)- filters:",
            filters
          );

          // update current filters
          updateCurrentFilters(filters);

          // re-format selected filters ...
          formatSelectedFilters();
        }}
        onClear={(componentId, value) => {
          console.log(
            `[SelectedFilters] onClear - ${componentId} has been removed with value:`,
            value
          );

          const dayTimePeriodIdRegex = /^[a-z]+-from$/;

          if (componentId === "geo-distance") {
            initCurrentGeoLocation();
          } else if (dayTimePeriodIdRegex.test(componentId)) {
            // NB: force 2nd onChange by setting select value to null

            const elt = document.getElementById(componentId);
            if (elt) {
              elt.value = null;
            }
          }
        }}
      />
    </section>
  );
}
