import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Divider as MuiDivider, Typography, Grid } from "@mui/material";
import { spacing } from "@mui/system";

import UserProfileView from "./UserProfileView";

const Divider = styled(MuiDivider)(spacing);

function UserProfile() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("menu.user-profile")} />

      <Typography
        variant="h3"
        gutterBottom
        display="grid"
        className="view-title"
      >
        {t("menu.user-profile")}
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <UserProfileView />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UserProfile;
