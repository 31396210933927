import React from "react";

import { Helmet } from "react-helmet-async";

import { Divider as MuiDivider, Grid, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import CareerView from "./CareerView";

const Divider = styled(MuiDivider)(spacing);

function Career() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet title={t("menu.career")} />
      <Typography
        variant="h3"
        gutterBottom
        display="grid"
        className="view-title"
      >
        {t("menu.career")}
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CareerView />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Career;
