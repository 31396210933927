import React, { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";

import useAuth from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";
import useSearchJob from "../../hooks/useSearchJob";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import { isMobileDevice } from "../../helpers/common.helpers";

import ExperienceForm from "./ExperienceForm";

const Experiences = (props) => {
  const { employeeConnector } = useAuth();
  const { repositories } = useSearchJob();
  const alert = useAlert();
  const { t, i18n } = useTranslation();
  const [experiences, setExperiences] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [editExperience, setEditExperience] = useState(null);
  const [loading, setLoading] = useState(false);
  const experienceRef = useRef(null);

  const language = i18n.language;

  const getDurationFromDates = ({ start, end }, age = false) => {
    let durationYear = end.year - start.year;
    let durationMonth = end.month - start.month;
    if (durationMonth < 0) {
      durationYear -= 1;
      durationMonth = 12 + durationMonth;
    }

    let result = "";

    if (durationYear > 0) {
      result +=
        `${durationYear} ` +
        (durationYear === 1
          ? t("user-profile.experience.yearOld")
          : t("user-profile.experience.yearsOld"));
    }

    if (durationMonth > 0) {
      result += durationYear > 0 ? ` ${t("user-profile.experience.and")} ` : "";
      result +=
        `${durationMonth} ` +
        (durationMonth === 1
          ? t("user-profile.experience.monthOld")
          : t("user-profile.experience.monthsOld"));
    }
    if (age && language === "en") result += " old";

    if (durationYear < 0 || (durationYear === 0 && durationMonth <= 0)) {
      result = t("user-profile.experience.newBorn");
    }
    return result;
  };

  /**
   * Handle submit new experience
   * @param {Object} values
   * @returns {void}
   */
  const handleAddExperience = async (values) => {
    setLoading("add");
    try {
      const result = await employeeConnector.addExperience(values);
      if (result) {
        const newExperience = result.data.results[0];
        setExperiences([...experiences, newExperience]);
        setLoading(false);
        alert.success({ message: t("alert.success.add") });
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[Experiences] handleAddExperience", error);
    }
  };

  /**
   * Handle submit existing experience edition
   * @param {Object} experience
   * @param {Object} values
   * @returns {void}
   */
  const handleUpdateExperience = async (experience, values) => {
    setLoading("loading");
    try {
      const result = await employeeConnector.updateExperience(
        experience.experience_id,
        values
      );
      if (result) {
        const newExperience = result.data.results[0];
        setExperiences([
          ...experiences.filter(
            (exp) => exp.experience_id !== experience.experience_id
          ),
          newExperience,
        ]);
        setLoading(false);
        alert.success({ message: t("alert.success.update") });
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[Experiences] handleUpdateExperience", error);
    }
  };

  /**
   * Handle open form
   * @returns {void}
   */
  const handleOpenForm = () => {
    setOpenForm(true);
  };

  /**
   * Handle close form
   * @returns {void}
   */
  const handleCloseForm = () => {
    setEditExperience(null);
    setOpenForm(false);
    if (isMobileDevice())
      experienceRef.current.scrollIntoView({
        alignToTop: true,
        behavior: "smooth",
      });
  };

  /**
   * Handle edit existing experience
   * @param {Object} experience
   * @returns {void}
   */
  const handleEdit = (experience) => {
    setEditExperience(experience);
    handleOpenForm();
  };

  /**
   * Handle delete experience
   * @param {Object} experience
   * @returns {void}
   */
  const handleDeleteExperience = async (experience) => {
    setLoading("delete");
    try {
      const result = await employeeConnector.deleteExperience(
        experience.experience_id
      );
      if (result) {
        setExperiences([
          ...experiences.filter(
            (exp) => exp.experience_id !== experience.experience_id
          ),
        ]);
        setLoading(false);
        alert.success({ message: t("alert.success.delete") });
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[Experiences] handleDeleteExperience", error);
    }
  };

  /**
   * Get user experiences
   * @returns {void}
   */
  const getExperiences = useCallback(async () => {
    setLoading("loading");
    try {
      const result = await employeeConnector.getExperiences();
      if (result) {
        setExperiences(result.data.results.experiences);
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[Experiences] getExperiences", error);
    }
  }, [employeeConnector, alert]);

  /**
   * Render edition dialog in desktop mode
   * @returns {Object}
   */
  const renderEditDialog = () => {
    return (
      <Dialog fullWidth open={openForm} onClose={handleCloseForm}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4" className="modal-medium-title">
              {t("user-profile.newExperience")}
            </Typography>
            <IconButton onClick={handleCloseForm}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <ExperienceForm
            experience={editExperience}
            handleCloseForm={handleCloseForm}
            handleAddExperience={handleAddExperience}
            handleUpdateExperience={handleUpdateExperience}
          />
        </DialogContent>
      </Dialog>
    );
  };

  /**
   * Render experiences list
   * @returns {Array}
   */
  const renderExperiences = () => {
    if (loading)
      return (
        <Box display="flex">
          <Box mr="2em">
            <CircularProgress />
          </Box>
          <Typography pt="0.5em">{t(`loader.${loading}`)}</Typography>
        </Box>
      );
    return Array.isArray(experiences) && experiences.length > 0 ? (
      experiences.map((experience, index) => {
        return (
          <Box key={index} mb="2em">
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6">{`${t(
                "user-profile.experience.experience"
              )} n°${index + 1}`}</Typography>
              <Box>
                {experience.validation ? (
                  <LockIcon sx={{ color: "red" }} />
                ) : (
                  <>
                    <IconButton
                      className="icon"
                      onClick={() => handleEdit(experience)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      className="icon"
                      onClick={() => handleDeleteExperience(experience)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </Box>
            <Typography mb="0.5em">
              {experience.type_id
                ? repositories.childcares.contexts[experience.type_id][language]
                : ""}
            </Typography>
            <Typography mb="0.5em">
              {`${t("user-profile.experience.duration")} : 
              ${getDurationFromDates({
                start: {
                  month: experience.month_start,
                  year: experience.year_start,
                },
                end: {
                  month: experience.month_end,
                  year: experience.year_end,
                },
              })}`}
            </Typography>
            {experience.children.map((child, index) => {
              return (
                <Box mb="0.5em" key={index}>
                  <Typography>{`${t("user-profile.experience.child")} n°${
                    index + 1
                  } : ${getDurationFromDates(
                    {
                      start: {
                        month: child.birth_month,
                        year: child.birth_year,
                      },
                      end: {
                        month: experience.month_start,
                        year: experience.year_start,
                      },
                    },
                    true
                  )}`}</Typography>
                </Box>
              );
            })}
            <Typography mb="0.5em">
              {`${t("user-profile.experience.missions")} : `}
              {experience.missions.map((mission, index) => {
                return index < experience.missions.length - 1
                  ? `${
                      repositories.childcares.responsibilities[
                        mission.mission_id
                      ][language]
                    }, `
                  : repositories.childcares.responsibilities[
                      mission.mission_id
                    ][language];
              })}
            </Typography>
          </Box>
        );
      })
    ) : (
      <Typography display={openForm ? "none" : "block"}>
        {t("user-profile.noExperiences")}
      </Typography>
    );
  };

  useEffect(() => {
    if (experiences === null && employeeConnector && !loading) getExperiences();
  }, [experiences, getExperiences, employeeConnector, loading]);

  return (
    <>
      <Box
        ref={experienceRef}
        display="flex"
        justifyContent="space-between"
        mb="1em"
      >
        <Typography variant="h4">
          {t("user-profile.professionalExperiences")}
        </Typography>
        <IconButton
          className="icon"
          pt={0}
          color="primary"
          onClick={handleOpenForm}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Box>
      {openForm && isMobileDevice() ? (
        <Box mb="2em">
          <ExperienceForm
            experience={editExperience}
            handleCloseForm={handleCloseForm}
            handleAddExperience={handleAddExperience}
            handleUpdateExperience={handleUpdateExperience}
          />
        </Box>
      ) : null}
      {renderExperiences()}
      {!isMobileDevice() ? renderEditDialog() : null}
    </>
  );
};

export default Experiences;
