import { isMobile } from "react-device-detect";

import { appConfig } from "../config";

/**
 * Returns true if mobile device detected
 * cf: https://www.npmjs.com/package/react-device-detect
 * @returns {Boolean}
 */
export const isMobileDevice = () => {
  return isMobile;
};

/**
 * Returns true if desktop device detected
 * cf: https://www.npmjs.com/package/react-device-detect
 * @returns {Boolean}
 */
export const isDesktopDevice = () => {
  return !isMobile;
};

/**
 * Scroll AppContent View to top
 * @returns {void}
 */
export const scrollAppContentToTop = () => {
  if (document.getElementById(appConfig?.reactiveListScrollTargetEltId)) {
    document
      .getElementById(appConfig.reactiveListScrollTargetEltId)
      .scrollTo(0, 0);
  }
};

/**
 * Returns true if current view contains "pushes"
 * @param {String} currentView
 * @returns {Boolean}
 */
export const isPushesView = (currentView) => {
  return currentView === "my-offers/pushes";
};

/**
 * Force file download from stream data
 * @param {Object} data
 * @param {String} contentType
 * @param {String} filename
 * @returns {Void}
 */
export const downloadFile = (data, contentType, filename = "file") => {
  console.log("[downloadFile] data, filename:", data, contentType, filename);

  let mimeType, fileExtension;

  // mimeType / file extension
  switch (contentType) {
    case "image/png":
      mimeType = "image/png";
      fileExtension = "png";
      break;

    case "image/jpeg":
      mimeType = "image/jpeg";
      fileExtension = "jpg";
      break;

    case "application/pdf":
      mimeType = "application/pdf";
      fileExtension = "pdf";
      break;

    default:
      mimeType = null;
      fileExtension = null;
  }

  if (!mimeType || !fileExtension) {
    throw new Error(
      `[downloadFile] no mimeType / fileExtension found with contentType: ${contentType}`
    );
  }

  const blob = new Blob([data], { type: mimeType });

  // IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob);
    return;
  }

  // Other browsers
  // Create a link pointing to the ObjectURL containing the blob.
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");

  a.style.display = "none";
  a.href = url;
  a.download = `${filename}.${fileExtension}`;

  document.body.appendChild(a);
  a.click();
  a.remove();

  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(url);
  }, 100);
};

/**
 * Enable / disable window.console
 * @param {String} isEnabled
 * @param {Array} allowedMethods
 * @returns {Void}
 */
export const initConsole = (isEnabled = true, allowedMethods = []) => {
  if (isEnabled) {
    return;
  }

  // disable all methods
  const noop = () => {};

  const methods = [
    "assert",
    "clear",
    "count",
    "debug",
    "dir",
    "dirxml",
    "error",
    "exception",
    "group",
    "groupCollapsed",
    "groupEnd",
    "info",
    "log",
    "markTimeline",
    "profile",
    "profileEnd",
    "table",
    "time",
    "timeEnd",
    "timeline",
    "timelineEnd",
    "timeStamp",
    "trace",
    "warn",
  ];

  // allowed methods
  const filteredMethods = methods.filter((method) => {
    return !allowedMethods.includes(method);
  });

  filteredMethods.forEach((method) => {
    window.console[method] = noop;
  });
};
