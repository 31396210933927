import * as Joi from "joi";
import packageInfo from "../package.json";

// console.info("[config] process:", process);
// console.info("[config] import.meta.env:", import.meta.env);
// console.info("[config] process.env:", process.env);
const envProcessVars = { ...process.env };

// Remove 'unauthorized' Joi entries
const removedEnvProcessVars = [
  "WDS_SOCKET_HOST",
  "WDS_SOCKET_PATH",
  "WDS_SOCKET_PORT",
  "FAST_REFRESH",
  "NODE_ENV",
  "PUBLIC_URL",

  // Local test suites ...
  "TERM_PROGRAM",
  "NODE",
  "INIT_CWD",
  "TERM",
  "SHELL",
  "npm_config_metrics_registry",
  "TMPDIR",
  "npm_config_global_prefix",
  "TERM_PROGRAM_VERSION",
  "COLOR",
  "TERM_SESSION_ID",
  "npm_config_noproxy",
  "npm_config_local_prefix",
  "USER",
  "COMMAND_MODE",
  "npm_config_globalconfig",
  "SSH_AUTH_SOCK",
  "__CF_USER_TEXT_ENCODING",
  "npm_execpath",
  "PATH",
  "npm_package_json",
  "_",
  "LaunchInstanceID",
  "npm_config_userconfig",
  "npm_config_init_module",
  "__CFBundleIdentifier",
  "npm_command",
  "PWD",
  "npm_lifecycle_event",
  "EDITOR",
  "npm_package_name",
  "LANG",
  "ITERM_PROFILE",
  "XPC_FLAGS",
  "npm_config_node_gyp",
  "npm_package_version",
  "XPC_SERVICE_NAME",
  "SHLVL",
  "HOME",
  "COLORFGBG",
  "LC_TERMINAL_VERSION",
  "ITERM_SESSION_ID",
  "npm_config_cache",
  "LOGNAME",
  "npm_lifecycle_script",
  "npm_config_user_agent",
  "LC_TERMINAL",
  "SECURITYSESSIONID",
  "npm_node_execpath",
  "npm_config_prefix",
  "COLORTERM",
  "PORT",
  "NODE_PATH",
  "BABEL_ENV",
  "JEST_WORKER_ID",
  "FORCE_COLOR",
];

for (const key of Object.keys(envProcessVars)) {
  if (removedEnvProcessVars.includes(key)) {
    delete envProcessVars[key];
  }
}
// console.info("[config] envProcessVars:", envProcessVars);

const envVarsSchema = Joi.object().keys({
  REACT_APP_ENV: Joi.string()
    .valid("production", "development", "staging")
    .required(),

  REACT_APP_NAME: Joi.string().required(),

  REACT_APP_DEFAULT_LANGUAGE: Joi.string()
    .valid("fr-FR", "en-GB")
    .default("fr-FR"),

  // REACT_APP_AUTH0_CLIENT_ID: Joi.string().required(),
  // REACT_APP_AUTH0_DOMAIN: Joi.string().required(),
  // REACT_APP_AUTH0_AUTH_URL: Joi.string().required(),
  // REACT_APP_AUTH0_LOGOUT_URL: Joi.string().required(),

  // REACT_APP_PERMISSIONS_API_URL: Joi.string().required(),
  // REACT_APP_PERMISSIONS_API_CODE: Joi.string().required(),

  REACT_APP_BASE_PATH: Joi.string().allow("").default(""),
  REACT_APP_JWT_BYPASS: Joi.boolean().default(false),
  REACT_APP_USER_BLOCKING_ACTIONS_BYPASS: Joi.boolean().default(false),

  REACT_APP_SAM_PROXY_API_URL: Joi.string().required(),

  REACT_APP_STORED_USER_EXPIRATION_MINUTES: Joi.number().default(30),

  REACT_APP_APPBASE_IO_CLUSTER_NAME: Joi.string().required(),
  REACT_APP_APPBASE_IO_CREDENTIALS: Joi.string().required(),
  REACT_APP_APPBASE_IO_URL: Joi.string().required(),
  REACT_APP_APPBASE_IO_GMAP_KEY: Joi.string().required(),
  REACT_APP_GMAP_KEY: Joi.string().required(),

  REACT_APP_WEBHOOK_REFRESH_FREQUENCY: Joi.number().default(3000),

  REACT_APP_UPLOAD_MAX_FILE_SIZE: Joi.number().default(2), // Megas
  REACT_APP_UPLOAD_ALLOWED_FILE_MIMETYPES:
    Joi.string().default("jpeg,jpg,png,pdf"),

  REACT_APP_LOGGLY_CUSTOMER_TOKEN: Joi.string().required(),
  REACT_APP_LOGGLY_TAG: Joi.string().required(),
});

const { value: envVars, error } = envVarsSchema
  .prefs({ errors: { label: "key" } })
  .validate(envProcessVars);

if (error) {
  throw new Error(`[config] Config validation error: ${error.message}`);
}
// console.info("[config] envVars:", envVars);

export const auth0Config = {
  // clientId: envVars.REACT_APP_AUTH0_CLIENT_ID,
  // domain: envVars.REACT_APP_AUTH0_DOMAIN,
  // authURL: envVars.REACT_APP_AUTH0_AUTH_URL,
  // logoutURL: envVars.REACT_APP_AUTH0_LOGOUT_URL,
};

export const apiConfig = {
  samApi: {
    url: envVars.REACT_APP_SAM_PROXY_API_URL,
    jobOfferInternalKey: "id",
    jobOfferPublicKey: "reference",

    // SAM auth
    auth: `${envVars.REACT_APP_SAM_PROXY_API_URL}/auth/login`,

    // Employee
    employeeAuth: `${envVars.REACT_APP_SAM_PROXY_API_URL}/auth/employee/login`,
    employeeAutoSignIn: `${envVars.REACT_APP_SAM_PROXY_API_URL}/auth/employee/auto-sign-in`,
  },

  gmap: {
    gMapApiKey: envVars.REACT_APP_GMAP_KEY,
  },

  // permissionsApi: {
  //   url: envVars.REACT_APP_PERMISSIONS_API_URL,
  //   code: envVars.REACT_APP_PERMISSIONS_API_CODE,
  //   auth: `${envVars.REACT_APP_PERMISSIONS_API_URL}/auth`,
  //   user: `${envVars.REACT_APP_PERMISSIONS_API_URL}/greetme`,
  //   XSRFToken: `${envVars.REACT_APP_PERMISSIONS_API_URL}/csrf-token`,
  // },
};

export const appBaseIOConfig = {
  clusterName: envVars.REACT_APP_APPBASE_IO_CLUSTER_NAME,
  credentials: envVars.REACT_APP_APPBASE_IO_CREDENTIALS,
  url: envVars.REACT_APP_APPBASE_IO_URL,

  gMapApiKey: envVars.REACT_APP_APPBASE_IO_GMAP_KEY,
};

export const appConfig = {
  env: envVars.REACT_APP_ENV,
  country: envVars.REACT_APP_DEFAULT_COUNTRY,
  name: envVars.REACT_APP_NAME,
  version: packageInfo.version,
  code: packageInfo.name,
  author: packageInfo.author,
  description: packageInfo.description,
  brand: "Mômji",
  basePath: envVars.REACT_APP_BASE_PATH,
  // basePath: envVars.REACT_APP_BASE_PATH.length
  //   ? `/${envVars.REACT_APP_BASE_PATH}`
  //   : "",

  allowedLanguages: ["fr-FR", "en-GB"],
  defaultLanguage: envVars.REACT_APP_DEFAULT_LANGUAGE,
  UIDefaultLanguage: "fr",
  webHookRefreshFrequency: envVars.REACT_APP_WEBHOOK_REFRESH_FREQUENCY,

  // auth0Config,
  apiConfig,
  appBaseIOConfig,

  jwtByPass: envVars.REACT_APP_JWT_BYPASS,
  userBlockingActionsByPass: envVars.REACT_APP_USER_BLOCKING_ACTIONS_BYPASS,

  storedUserExpirationMinutes: envVars.REACT_APP_STORED_USER_EXPIRATION_MINUTES,

  reactiveListScrollTargetEltId: "app-content",
  mobileDrawerToggleEltId: "mobile-drawer-toggle",

  upload: {
    maxFileSize: envVars.REACT_APP_UPLOAD_MAX_FILE_SIZE,
    allowedMimeTypes:
      envVars.REACT_APP_UPLOAD_ALLOWED_FILE_MIMETYPES.split(","),
  },

  loggly: {
    token: envVars.REACT_APP_LOGGLY_CUSTOMER_TOKEN,
    tag: envVars.REACT_APP_LOGGLY_TAG,
  },

  scripts: {
    "intl-tel-input": {
      url: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js",
    },
  },
};
