import {
  Bookmark,
  Grid,
  User,
  Home,
  Layers,
  Calendar,
  /*HelpCircle,*/
  Archive,
} from "react-feather";

import i18n from "i18next";

//import { appConfig } from "../../config";

const mainSection = [
  {
    href: "/",
    title: i18n.t("menu.home"),
    icon: Home,
    id: "menu.home",
  },
  {
    href: "/career",
    title: i18n.t("menu.career"),
    icon: User,
    id: "menu.career",
  },
  {
    href: "/job-offers",
    title: i18n.t("menu.job-offers"),
    icon: Grid,
    id: "menu.job-offers",
  },
  {
    href: "/my-offers",
    title: i18n.t("menu.my-offers.my-offers"),
    icon: Bookmark,
    id: "menu.my-offers",
    children: [
      {
        href: "/my-offers/applications",
        title: i18n.t("menu.my-offers.applications"),
        id: "menu.my-offers.applications",
      },
      {
        href: "/my-offers/bookmarks",
        title: i18n.t("menu.my-offers.bookmarks"),
        id: "menu.my-offers.bookmarks",
      },
      {
        href: "/my-offers/pushes",
        title: i18n.t("menu.my-offers.pushes"),
        id: "menu.my-offers.pushes",
      },
    ],
  },

  {
    href: "/contracts",
    title: i18n.t("menu.contracts.contracts"),
    icon: Layers,
    id: "menu.contracts.contracts",
    children: [
      {
        href: "/contracts/contracts",
        title: i18n.t("menu.contracts.contracts"),
        id: "menu.contracts.contracts",
      },
      {
        href: "/contracts/due",
        title: i18n.t("menu.contracts.due"),
        id: "menu.contracts.due",
      },
      {
        href: "/contracts/healthcares",
        title: i18n.t("menu.contracts.healthcare"),
        id: "menu.contracts.healthcare",
      },
    ],
  },
  {
    href: "/planning",
    title: i18n.t("menu.planning"),
    icon: Calendar,
    id: "menu.planning",
  },
  {
    href: "/payslips",
    title: i18n.t("menu.payslips"),
    icon: Archive,
    id: "menu.payslips",
  },
  /*{
    href: "/resources",
    title: i18n.t("menu.resources"),
    icon: HelpCircle,
    id: "menu.resources",
  },*/
];

const navItems = [
  {
    title: "MENU",
    pages: mainSection,
  },
];

export default navItems;
