import { amber, green, red } from "@mui/material/colors";

const colorStatus = {
  Terminé: {
    text: red[500],
    background: red[50],
  },
  "A signer": {
    text: amber[700],
    background: amber[50],
  },
  "En cours": {
    text: green[500],
    background: green[50],
  },
};

const actions = {
  to_sign: "sign",
  to_read: "file",
};

const healthcareChoices = {
  ACCEPTED: "1",
  REFUSED: "2",
};

const healthcareActions = {
  CHOOSE: "choose",
  SIGN: "sign",
  COMPLETE: "complete",
};

export { colorStatus, actions, healthcareChoices, healthcareActions };
