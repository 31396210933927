import { appConfig } from "../../../config";

/**
 * componentId filters
 * @returns {Array}
 */
const SEARCH_FILTER_COMPONENT_IDS = [
  // -- visible filters
  "global-search",
  "languages",

  // "cities",
  "job-offer-types",
  "job-offer-sub-types",

  "availabilities",
  // "package-rating",
  "hours-by-week",

  "monday-from",
  "tuesday-from",
  "wednesday-from",
  "thursday-from",
  "friday-from",
  "saturday-from",
  // "sunday-from",

  // "monday-time-periods",
  // "tuesday-time-periods",
  // "wednesday-time-periods",
  // "thursday-time-periods",
  // "friday-time-periods",
  // "saturday-time-periods",
  // "sunday-time-periods",

  // "city-search",
  "geo-distance",

  "reference",

  "children-ages",
  "children-total",

  // -- hidden filters
  "job-offer-ids", // NB : query
  "languages-levels", // NB : query
  "childcare-skills", // NB : query
];

/**
 * Returns filtered filters
 * @param {Array} excludedFilters
 * @returns {Array}
 */
const getFilters = (excludedFilters = []) => {
  return SEARCH_FILTER_COMPONENT_IDS.filter((filter) => {
    return !excludedFilters.includes(filter);
  });
};

/**
 * Returns mapped filter data
 * @param {Object} dataField
 * @param {Object} data
 * @param {Object} repositories
 * @param {String} language
 * @returns {Object}
 */
// const mapFilterData = (
//   dataField,
//   data,
//   repositories,
//   language = appConfig.defaultLanguage
// ) => {
//   // console.log("[mapFilterData]", dataField, data, repositories, language);

//   const repository = repositories[dataField];

//   if (!repository) {
//     throw new Error(`repository ${dataField} not found !`);
//   }
//   const mappedData = data.map((d) => {
//     const obj = {
//       key: "",
//       doc_count: d.doc_count,
//     };

//     obj.key = repository[d.key][language];

//     return obj;
//   });
//   // console.log("[mapFilterData]", mappedData);
//   return mappedData;
// };

/**
 * Returns filters values
 * @param {Object} filters
 * @returns {Object}
 */
const getFiltersValues = (appBaseFilters) => {
  console.log("[getFiltersValues]", appBaseFilters);
  const excludedFilters = ["result", "job-offer-ids"];
  const filters = {};

  if (!appBaseFilters) {
    return filters;
  }

  // NB: we only send values for each filter
  for (const key of Object.keys(appBaseFilters)) {
    if (!excludedFilters.includes(key)) {
      const value = appBaseFilters[key].value;
      if (value) {
        // no empty value
        filters[key] = value;
      }
    }
  }
  return filters;
};

/**
 * Returns appBaseFilters values
 * @param {Object} appBaseFilters
 * @param {String} appBaseFilterKey
 * @returns {Object}
 */
const getFiltersValue = (appBaseFilters, appBaseFilterKey) => {
  // console.log("[getFiltersValue]", appBaseFilters, appBaseFilterKey);

  return appBaseFilters &&
    typeof appBaseFilters[appBaseFilterKey] !== "undefined"
    ? appBaseFilters[appBaseFilterKey]
    : null;
};

/**
 * Returns RangeSlider normalized default values
 * @param {Array} values
 * @returns {Object}
 */
const getRangeSliderDefaultValues = (values) => {
  let start = 1;
  let end = 5;

  if (values && Array.isArray(values) && values.length > 1) {
    start = values[0];
    end = values[1];
  }

  return {
    start,
    end,
  };
};

/**
 * Returns Custom item for MultiList
 * @returns {Array}
 */
const getMultiListCustomItem = (
  label,
  count,
  isSelected,
  dataField = null,
  repositories = null,
  language = appConfig.defaultLanguage,
  t,
  extraComponent = null,
  showCount = true
) => {
  const prefix = count > 1 ? t("search-job.offers") : t("search-job.offer");
  let className = isSelected ? "label selected" : "label";
  // console.log("[getMultiListCustomItem]", label, count, dataField);

  if (!count) {
    className = `${className} no-item`;
  }

  if (typeof count === "undefined") {
    count = 0;
  }

  let renderedLabel = label;

  if (dataField) {
    // we use translated label
    const repository =
      typeof repositories[dataField] !== "undefined"
        ? repositories[dataField]
        : null;

    if (!repository) {
      throw new Error(`repository ${dataField} not found !`);
    }
    if (
      typeof repository[label] !== "undefined" &&
      typeof repository[label][language] !== "undefined"
    ) {
      renderedLabel = repository[label][language];
    }
  }

  const extraComponentWrapper = extraComponent ? <>{extraComponent}</> : null;

  return (
    <>
      <span className={className}>
        {renderedLabel}
        {showCount && <span className="count">({`${count} ${prefix}`})</span>}
      </span>
      {extraComponentWrapper}
    </>
  );
};

/**
 * Returns Selected Day Time Periods
 * @returns {Object}
 */
const getSelectedDayTimePeriods = () => {
  const selectedDayTimePeriods = {};

  const selectElts = document.querySelectorAll(".availabilities-filter select");

  for (const selectElt of selectElts) {
    const id = selectElt.id;
    const value = Number(selectElt.value);

    if (value) {
      selectedDayTimePeriods[id] = value;
    }
  }
  return selectedDayTimePeriods;
};

export {
  SEARCH_FILTER_COMPONENT_IDS,
  getFilters,
  getFiltersValues,
  getFiltersValue,
  getRangeSliderDefaultValues,
  getMultiListCustomItem,
  getSelectedDayTimePeriods,
};
