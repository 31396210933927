import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import CloseIcon from "@mui/icons-material/Close";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import useAuth from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";

const Button = styled(MuiButton)(spacing);
const langMap = {
  en: "en-GB",
  fr: "fr-FR",
};

const lorem =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In aliquet ut leo vel euismod. Phasellus cursus efficitur arcu, non commodo quam eleifend sed. Curabitur sed varius justo. Cras sagittis nibh metus, ac fringilla nibh pharetra non. Pellentesque in commodo ante, a malesuada ligula. Proin ac ipsum rutrum, iaculis enim in, ultrices felis. In hac habitasse platea dictumst. Nam nec ipsum ac nulla semper maximus. Nulla urna lorem, placerat eget libero in, consequat vestibulum nunc. Etiam dictum erat congue, dapibus turpis id, rhoncus nunc.";

const Engagement = (props) => {
  const { cardSx } = props;
  const { t, i18n } = useTranslation();
  const alert = useAlert();
  const { employeeConnector } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);
  const [approved, setApproved] = useState(null);
  const [hasRead, setHasRead] = useState(null);
  const [text, setText] = useState("");

  const language = i18n.language;

  /**
   * Handle the engagement convention approving
   * @returns {void}
   */
  const handleApproveChange = () => {
    setHasRead(!hasRead);
  };

  /**
   * Handle engagement dialog opening
   * @returns {void}
   */
  const handleOpenEngagement = () => {
    setOpenDialog(true);
  };

  /**
   * Handle engagement dialog closing
   * @returns {void}
   */
  const handleCloseEngagement = () => {
    setOpenDialog(false);
  };

  /**
   * Handle the engagement convention validation
   * @returns {void}
   */
  const handleValidateEngagement = async () => {
    try {
      const result = await employeeConnector.postEngagement();
      if (result) {
        setApproved(true);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[CareerView] postEngagement", error);
    }
    handleCloseEngagement();
  };

  /**
   * Get status of the user's engagement
   * @returns {void}
   */
  const getApproved = useCallback(async () => {
    try {
      const result = await employeeConnector.getEngagement();
      if (result) {
        setApproved(result.data.results.confirmation);
        const translatedText = result.data.results.text[langMap[language]]
          ? result.data.results.text[langMap[language]]
          : lorem;
        setText(translatedText);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[CareerView] getEngagement", error);
    }
  }, [employeeConnector, alert, language]);

  useEffect(() => {
    if (approved === null && employeeConnector) {
      getApproved();
    }
  }, [approved, employeeConnector, getApproved]);

  return (
    <>
      <Card sx={cardSx}>
        <CardContent>
          <Typography mb="1em" variant="h4">
            {t("career.engagement.title")}
          </Typography>
          <Typography mb="2em">
            {approved
              ? t("career.engagement.has-read")
              : t("career.engagement.description")}
          </Typography>

          <Box display="flex" justifyContent="center">
            <Button
              disabled={!text}
              variant="contained"
              size="large"
              onClick={handleOpenEngagement}
            >
              {approved
                ? t("career.engagement.see-engagement")
                : t("career.engagement.read")}
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Dialog open={openDialog}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography pl="25%" variant="h4">
              {t("career.engagement.modal-title")}
            </Typography>
            <IconButton onClick={handleCloseEngagement}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography mb="1em">{text}</Typography>
          {approved ? null : (
            <Box>
              <Box display="flex">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasRead}
                      onChange={handleApproveChange}
                    />
                  }
                  label={t("career.engagement.check")}
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  disabled={!hasRead}
                  variant="contained"
                  size="large"
                  onClick={handleValidateEngagement}
                >
                  {t("career.engagement.validate")}
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Engagement;
