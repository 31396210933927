import React, { createContext, useEffect, useReducer } from "react";

import axios from "axios";

import { appConfig } from "../config.js";
import { getSamAuthHeaders, getApiNormalizedErrors } from "../utils/sam.api.js";
// import { logError } from "../connectors/connector.helpers.js";

import EmployeeConnector from "../connectors/sam-api/employee/EmployeeConnector";
import { getSamProxyXHRConfig } from "../connectors/connector.helpers";

import { LOCALSTORAGE_ITEM_KEYS } from "../constants.js";

import {
  getStoredUser,
  hasExpirationDateExpired,
} from "../helpers/auth.helpers";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  employeeConnector: null,
};

const reducer = (state, action) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user, employeeConnector } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      employeeConnector,
    };
  }
  if (action.type === SIGN_IN) {
    const { user } = action.payload;
    const samAuthHeaders = getSamAuthHeaders(user);
    const employeeConnector = new EmployeeConnector(
      user.id,
      getSamProxyXHRConfig(samAuthHeaders)
    );
    return { ...state, isAuthenticated: true, user, employeeConnector };
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      employeeConnector: null,
    };
  }
  return state;
};

const AuthContext = createContext(null);

/**
 * AuthProvider
 */
function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // const url = window.location.href;
  // const queryStringFragments = url.split("?").slice(1);

  useEffect(() => {
    const initialize = async () => {
      console.info("[AuthProvider] initialize");
      try {
        // we retrieve stored user from localstorage
        const user = getStoredUser();

        if (!user) {
          // kill local session
          removeStoredUser();

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
              employeeConnector: null,
            },
          });
        }
        const expirationDate = user?.expirationDate;
        const isAuthenticated = !hasExpirationDateExpired(expirationDate);

        // console.log(
        //   "[AuthProvider] initialize - isAuthenticated:",
        //   isAuthenticated,
        //   expirationDate,
        //   new Date(expirationDate).getTime(),
        //   Date.now()
        // );

        if (isAuthenticated) {
          const samAuthHeaders = getSamAuthHeaders(user);
          const employeeConnector = new EmployeeConnector(
            user.id,
            getSamProxyXHRConfig(samAuthHeaders)
          );

          console.info("[AuthProvider] initialize dispatch", {
            isAuthenticated,
            user,
            employeeConnector,
          });
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated, user, employeeConnector },
          });
        } else {
          console.info("[AuthProvider] initialize dispatch", {
            isAuthenticated,
          });
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated, user: null, employeeConnector: null },
          });
        }
      } catch (error) {
        console.error("[AuthProvider] error:", error);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            employeeConnector: null,
          },
        });
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * SignIn Employee
   * @param {String} email
   * @param {String} password
   * @param {Boolean} rememberMe
   * @returns void
   */
  const signIn = async (email, password, rememberMe = false) => {
    // console.info("[AuthProvider] signIn", email, password, rememberMe);

    const result = await axios({
      method: "post",
      //url: appConfig.apiConfig.samApi.auth,
      url: appConfig.apiConfig.samApi.employeeAuth,
      data: {
        login: email,
        password,
      },
      headers: {
        cachePolicy: "no-cache",
      },
    }).catch((error) => {
      // logError(error, "[AuthProvider - signIn]");
      console.error("[AuthProvider] signIn :", error, error.response);

      if (error.response && error.response.data && error.response.data.error) {
        throw getApiNormalizedErrors(error);
      }
      throw error;
    });

    const user = result.data;

    if (!user.id) {
      throw new Error("[AuthProvider] signIn: unknown user !");
    }

    user.rememberMe = rememberMe;

    setStoredUser(user, rememberMe);

    console.info("[AuthProvider] signIn dispatch", {
      user,
    });

    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  /**
   * autoSignIn Employee
   * @param {String} login
   * @param {String} key
   * @param {Boolean} rememberMe
   * @returns void
   */
  const autoSignIn = async (login, key, rememberMe = false) => {
    console.info("[AuthProvider] autoSignIn", login, key, rememberMe);

    const result = await axios({
      method: "post",
      url: appConfig.apiConfig.samApi.employeeAutoSignIn,
      data: {
        login,
        key,
      },
      headers: {
        cachePolicy: "no-cache",
      },
    }).catch((error) => {
      // logError(error, "[AuthProvider - autoSignIn]");
      console.error("[AuthProvider] autoSignIn :", error, error.response);

      if (error.response && error.response.data && error.response.data.error) {
        throw getApiNormalizedErrors(error);
      }
      throw error;
    });

    const user = result.data;

    if (!user.id) {
      throw new Error("[AuthProvider] autoSignIn: unknown user !");
    }

    user.rememberMe = rememberMe;

    setStoredUser(user, rememberMe);

    console.info("[AuthProvider] autoSignIn dispatch", {
      user,
    });

    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  /**
   * SignOut Employee
   * NB: clear local storage session
   * @returns void
   */
  const signOut = async () => {
    // console.info("[AuthProvider] signOut");

    const user = getStoredUser(); // TODO : use updated state.user
    const samAuthHeaders = getSamAuthHeaders(user);

    // kill api session
    await axios({
      method: "post",
      url: `${appConfig.apiConfig.samApi.url}/employee/logout`,
      data: {},
      headers: samAuthHeaders,
    }).catch((error) => {
      // logError(error, "[AuthProvider - signOut]");
      console.error("[AuthProvider] signOut :", error, error.response);

      if (error.response && error.response.data && error.response.data.error) {
        throw getApiNormalizedErrors(error);
      }
      throw error;
    });

    // kill local session
    removeStoredUser();

    dispatch({ type: SIGN_OUT });
  };

  /**
   * Forgot Employee Password
   * @param {String} email
   * @returns void
   */
  const forgotPassword = async (email) => {
    // console.info("[AuthProvider] forgotPassword", email);
    const result = await axios({
      method: "post",
      url: `${appConfig.apiConfig.samApi.url}/auth/employee/forgot-password`,
      data: {
        email,
      },
      headers: {
        cachePolicy: "no-cache",
      },
    }).catch((error) => {
      // logError(error, "[AuthProvider - forgotPassword]");
      console.error("[AuthProvider] forgotPassword :", error, error.response);

      if (error.response && error.response.data && error.response.data.error) {
        throw getApiNormalizedErrors(error);
      }
      throw error;
    });
    // console.log("[AuthProvider] forgotPassword :", result);

    return result.data;
  };

  /**
   * Reset Employee Password
   * @param {String} password
   * @param {String} confirmPassword
   * @param {String} token
   * @returns void
   */
  const resetPassword = async (password, confirmPassword, token) => {
    // console.info("[AuthProvider] resetPassword", password, confirmPassword, token);
    const result = await axios({
      method: "post",
      url: `${appConfig.apiConfig.samApi.url}/auth/employee/reset-password`,
      data: {
        password,
        confirmPassword,
        token,
      },
      headers: {
        cachePolicy: "no-cache",
      },
    }).catch((error) => {
      // logError(error, "[AuthProvider - resetPassword]");
      console.error("[AuthProvider] resetPassword :", error, error.response);

      if (error.response && error.response.data && error.response.data.error) {
        throw getApiNormalizedErrors(error);
      }
      throw error;
    });
    // console.log("[AuthProvider] resetPassword :", result);

    return result.data;
  };

  /**
   * Set App User (get Employee profile)
   * @returns void
   */
  const setAppUser = async () => {
    // console.info("[AuthProvider] setAppUser");

    const user = getStoredUser(); // TODO : use updated state.user

    if (!user) {
      throw new Error("[AuthProvider] setAppUser: undefined user");
    }

    const samAuthHeaders = getSamAuthHeaders(user);
    const employeeConnector = new EmployeeConnector(
      user.id,
      getSamProxyXHRConfig(samAuthHeaders)
    );

    try {
      const response = await employeeConnector.getProfile();

      const employeeProfile = response.data;

      // we set user interviews filters used by jobOffer
      const { interviews } = employeeProfile;
      if (!interviews) {
        throw new Error("[AuthProvider] setAppUser: interviews not found !");
      }
      employeeProfile.filters = employeeConnector.getFilters(interviews);

      //  we set user emailAlert
      const emailAlert = await employeeConnector.getEmailAlert();
      employeeProfile.emailAlert = emailAlert.data;

      const beforModules = await employeeConnector.getBeforeModules();
      employeeProfile.blockingActions =
        beforModules.data.results.before_modules;

      employeeProfile.notifications =
        beforModules.data.results.message_notification;
      // we set user documents > setAppUserDocuments()
      // employeeProfile.documents = await employeeConnector.getDocuments();

      // last user profile load
      employeeProfile.lastLoadedOn = new Date();
      // console.log(
      //   "[AuthProvider] setAppUser - employeeProfile",
      //   employeeProfile,
      //   user
      // );
      const mergedUser = { ...user, ...employeeProfile };

      setStoredUser(mergedUser, user.rememberMe);

      dispatch({ type: SIGN_IN, payload: { user: mergedUser } });
    } catch (error) {
      console.error("[AuthProvider] setAppUser", error);

      // cookie no more active > signout !
      await signOut();

      throw error;
    }
  };

  /**
   * Set App User JobOffers
   * @returns void
   */
  const setAppUserJobOffers = async () => {
    // console.info("[AuthProvider] setAppUserJobOffers");

    const user = getStoredUser();

    if (!user) {
      throw new Error("[AuthProvider] setAppUserJobOffers: undefined user");
    }

    const samAuthHeaders = getSamAuthHeaders(user);
    const employeeConnector = new EmployeeConnector(
      user.id,
      getSamProxyXHRConfig(samAuthHeaders)
    );

    try {
      const jobOffers = await employeeConnector.getJobOffers();

      const obj = {
        jobOffers,
      };
      const mergedUser = { ...user, ...obj };

      setStoredUser(mergedUser, user.rememberMe);

      dispatch({ type: SIGN_IN, payload: { user: mergedUser } });
    } catch (error) {
      console.error("[AuthProvider] setAppUserJobOffers", error);

      // cookie no more active > signout !
      await signOut();

      throw error;
    }
  };

  /**
   * Set App User Documents
   * @returns void
   */
  const setAppUserDocuments = async () => {
    // console.info("[AuthProvider] setAppUserDocuments");

    const user = getStoredUser();

    if (!user) {
      throw new Error("[AuthProvider] setAppUserDocuments: undefined user");
    }

    const samAuthHeaders = getSamAuthHeaders(user);
    const employeeConnector = new EmployeeConnector(
      user.id,
      getSamProxyXHRConfig(samAuthHeaders)
    );

    try {
      const documents = await employeeConnector.getDocuments();
      const obj = {
        documents,
      };
      const mergedUser = { ...user, ...obj };

      setStoredUser(mergedUser, user.rememberMe);

      dispatch({ type: SIGN_IN, payload: { user: mergedUser } });
    } catch (error) {
      console.error("[AuthProvider] setAppUserDocuments", error);

      // cookie no more active > signout !
      await signOut();

      throw error;
    }
  };

  /**
   * Set App User Onboarding
   * @param {Number} onboarding
   * @returns void
   */
  const setAppUserOnboarding = (onboarding) => {
    const user = getStoredUser();

    const mergedUser = { ...user, onboarding: onboarding };
    setStoredUser({ ...mergedUser }, user.rememberMe);

    dispatch({ type: SIGN_IN, payload: { user: mergedUser } });
  };

  /**
   * Set App User Profile
   * @param {Object} profile
   */
  const setAppUserProfile = (profile) => {
    const user = getStoredUser();

    const mergedUser = { ...user, ...profile };
    setStoredUser(mergedUser, user.rememberMe);
    dispatch({ type: SIGN_IN, payload: { user: mergedUser } });
  };

  /**
   * Set App User Profile
   * @param {Object} profile
   */
  const setAppUserBlockingActions = (actions) => {
    const user = getStoredUser();

    const mergedUser = { ...user, ...actions };
    setStoredUser(mergedUser, user.rememberMe);
    dispatch({ type: SIGN_IN, payload: { user: mergedUser } });
  };

  /**
   * Set App User EmailAlert
   * @param {Object} emailAlert
   * @returns void
   */
  const setAppUserEmailAlert = (emailAlert) => {
    const user = getStoredUser();

    const mergedUser = { ...user, emailAlert: emailAlert };
    setStoredUser({ ...mergedUser }, user.rememberMe);

    dispatch({ type: SIGN_IN, payload: { user: mergedUser } });
  };

  /**
   * Returns stored user expiration date
   * @param {Number} minutes
   * @returns {Date}
   */
  const getExpirationDate = (minutes) => {
    const date = new Date();
    const timestamp = new Date(date.getTime() + Number(minutes) * 60000);
    return new Date(timestamp);
  };

  /**
   * Store User to localStorage
   * @param {Object} user
   * @param {Boolean} rememberMe
   * @returns {Object}
   */
  const setStoredUser = (user, rememberMe = false) => {
    // console.info("[AuthProvider] setStoredUser - rememberMe:", rememberMe);

    // Set expiration Date
    if (rememberMe) {
      user.expirationDate = getExpirationDate(
        appConfig.storedUserExpirationMinutes
      );
    } else {
      user.expirationDate = new Date();
    }

    window.localStorage.setItem(
      LOCALSTORAGE_ITEM_KEYS.USER,
      JSON.stringify(user)
    );
  };

  /**
   * Remove Stored User from localStorage
   * @returns {Object}
   */
  const removeStoredUser = () => {
    // console.info("[AuthProvider] removeStoredUser");
    window.localStorage.removeItem(LOCALSTORAGE_ITEM_KEYS.USER);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "sam",

        user: state.user,
        employeeConnector: state.employeeConnector,

        signIn,
        autoSignIn,
        signOut,
        forgotPassword,
        resetPassword,

        setAppUser,
        setAppUserJobOffers,
        setAppUserDocuments,
        setAppUserOnboarding,
        setAppUserProfile,
        setAppUserBlockingActions,
        setAppUserEmailAlert,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
