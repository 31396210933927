const translations = {
  common: {
    dateFieldInputFormat: "yyyy/MM/dd hh:mm",
    documentation: "Documentation",
  },
  datagrid: {
    id: "ID",
    name: "Last name",
    firstname: "First Name",
    userType: "User Type",
    code: "Code",
    isActive: "Active",
    isActiveF: "Active",
    edit: "Edit",
    delete: "Delete",
    activate: "Activate",
    deactivate: "Deactivate",
    actions: "Actions",
    type: "Type",
    httpMethod: "Méthode HTTP ",
    path: "Path",
    api: "API",
    role: "Role",
    action: "Action",
    department: "Department",
  },
  search: "Search topics ...",
  home: {
    title: "Dashboard",
    breadcrumb: "Home",
  },

  loader: {
    loading: "Loading",
    add: "Addition in progress",
    delete: "Deletion in progress",
    downloading: "Downloading",
  },
  form: {
    saveChanges: "Save",
    cancel: "Cancel",
    delete: "Delete",
    errors: {
      required: {
        type: "Type required",
        "start-date": "Start date incomplete",
        "end-date": "End date incomplete",
        "birth-date": "Birthdate required",
      },
      invalid: {
        "end-date": "End date must be posterior to start date",
        "birth-date": "Birth date must be anterior to start date",
      },
    },
  },
  dialog: {
    cancel: "Cancel",
    confirm: "Confirm",
    confirmChangeText: "Are you sure you want to save these changes?",
    confirmDeleteText: "Are you sure you want to delete this element",
    confirmCancel: "Some changes are not saved, do you want to continue?",
  },

  alert: {
    success: {
      create: "Created successfully",
      delete: "Deleted successfully",
      update: "Updated successfully",
      loading: "Loaded successfully",
      //apply: "Application saved",
      //bookmark: "The offer was bookmarked",
      //unbookmark: "The offer was removed from your bookmarks",
      "email-alert-on": "Email alert activated",
      "email-alert-off": "Email alert deactivated",
      "forgot-password": "An email was sent to your email address",
      "job-offer-bookmarked":
        "Job offer {{ jobOffer.reference }} was bookmarked",
      "job-offer-unbookmarked":
        "Job offer {{ jobOffer.reference }} was removed from your bookmarks",
      "job-offer-applied":
        "Applicated registered for the offer {{ jobOffer.reference }}",
      "add-experience": "Experience added",
      "job-offer-deleted":
        "The job offer  {{ jobOffer.reference }} has been deleted from your suggestions",
      "job-offer-unapplied":
        "Your application for the offer {{ jobOffer.reference }} has been removed",
      "to-clipboard": "Added to your clipboard",
    },
  },
  dropzone: {
    drophere: "Drag and drop your files or browse to upload",
    success: "Success",
    failed: "Failure",
  },
  menu: {
    home: "Dashboard",
    "job-offers": "Our job offers",
    contracts: {
      contracts: "Your contracts",
      due: "DUE",
      healthcare: "Healthcare",
    },
    payslips: "Your payslips",
    planning: "Your planning",
    documents: "Your documents",
    resources: "Resources",
    "user-profile": "Your profile",
    career: "Your Mômji journey",
    "my-offers": {
      "my-offers": "Your offers",
      applications: "Applications",
      bookmarks: "Bookmarks",
      pushes: "Suggestions",
    },
  },

  detail: {
    name: "Name",
    family_name: "Last Name",
    firstname: "First Name",
    given_name: "Given Name",
    userType: "User Type",
    isActive: "Active",
    isActiveF: "Active",
    id: "ID ",
    createdAt: "Created on",
    updatedAt: "Updated on",
    description: "Description",
    code: "Code",
    fieldsRequired: "The fields marked with a * sont required",
    createdBy: "Created by",
    updatedBy: "Updated by",
    type: "Type",
    httpMethod: "Méthode HTTP",
    roleType: "Type de rôle",
    department: "Department",
    action: "Action",
    api: "Api",
    path: "Path",
    email: "Email address",
    role: "Role",
    noValue: "No values",
  },

  auth: {
    loading: "Loading user data ...",
    welcome: "Welcome back",
    "missed-you": "We've missed you",
    "sign-in": "Sign in to your account to continue",
    profile: "Profile",
    "sign-out": "Sign out",
    "sign-out-progress": "Logout in progress ...",
    account: "Account",
    login: "Your email",
    password: "Your password",
    "remember-me": "Remember me",
    "forgot-password": "I forgot my password",
    "reset-password": "Enter new password",
    "reset-password-both-same": "Both password need to be the same",
    "reset-password-new": "Your new password",
    "reset-password-new-confirm": "Confirm your new password",
    submit: "Submit",
    validation: {
      "valid-email": "Invalid email address",
      "required-email": "Email address required",
      "required-password": "Password required",
    },

    "forgot-password-email":
      "Please enter your email address to reset your password",
    "forgot-password-submit": "Request a new password",
    "reset-password-email": "Please enter your new password",
    "reset-password-submit": "Save your new password",
    "reset-password-min-size": "Your password must be at least 12 characters",
    "auto-connect": "Connection to your space in progress….",
    "page-not-found-title": "Page not found.",
    "page-not-found-description":
      "The page you are looking for might have been removed ...",
    "back-to-website": "Return to website",
    "back-to-login": "Return to login page",
    required: "Required",
    "bad-password-format":
      "The password does not match the expected format. It must have: 8 characters, at least one number, at least one lowercase letter, at least one uppercase letter",
    "back-to-sign-in": "Go back to the login page",
    "internal-error-title": "Internal server error",
    "internal-error-description":
      "The server encountered something unexpected that didn't allow it to complete the request.",
  },

  "search-job": {
    application: "Application",
    rejected: "Application rejected",
    canceled: "Application canceled",
    meeting: "Meeting in progress",
    contract: "Contract in progress",
    transmission: "Application submitted",
    search: "Search",
    "search-loading": "Loading ...",
    "search-lazy-loading": "Loading offers ...",
    "search-data-loading": "Loading data ...",
    "my-offers-loading": "Loading your offers ...",
    "job-offer-types": "Job offer types",
    fromDate: "From",
    child: "Child",
    children: "Children",
    languages: "Languages",
    "languages-levels": "Levels by language",
    "starting-address": "Departure address",
    "starting-address-input": "Select a departure address",
    distance: "Distance",
    "starting-location": "Point of departure",
    filters: "Filters",
    filter: "Filter",
    "init-filters": "Initialize filters",
    "children-ages": "Age of youngest child",
    "children-ages-ranges": {
      1: "0 - 1 year",
      2: "1 - 2 years",
      3: "2 - 5 years",
      4: "+ 6 years",
    },
    "children-nber-ranges": {
      3: "3 and more",
    },
    "children-total": "Number of children",
    availabilities: "Availabilities",
    "childcare-skills": "Childcare skills",
    hoursByWeek: "Hours by week",
    days: {
      monday: "L",
      tuesday: "M",
      wednesday: "M",
      thursday: "J",
      friday: "V",
      saturday: "S",
      sunday: "D",
    },
    "time-from": "From",
    relevance: "relevance",
    package: "package",
    "zip-code": "zip code",
    asc: "ASC",
    desc: "DESC",
    reference: "Reference",
    "reference-placeholder": "Reference number",
    offer: "offer",
    offers: "offers",
    "email-alert": "Email alert",
    "email-alert-label":
      "Check this box if you want to receive new offers that match your criteria",
    btn: "See the offers {{ items }} ",
    "no-results": "No offer found ...",
    sorting: {
      label: "Order",
      distance: "Distance",
      "hours-by-week": "Hours by week",
      "starting-date": "Starting date",
      asc: "Ascending",
      desc: "descending",
    },
    "result-stats": {
      none: "No offer found ...",
      one: "<b>1</b> offer has been found",
      many: "<b>{{ numberOfResults }}</b> offers have been found",
    },
    "job-offer-sub-types": "Offer type",
  },

  "job-offer": {
    offer: "Job offer",
    status: "Status",
    dealstatus: {
      none: "You have not yet applied to this offer",
      transmission: "Your application has been submitted to the family",
      meeting:
        "Your application has been accepted and you are now the process of meeting the family",
      contract: "Your application has been accepted",
      archive: "Your application has been archived",
      rejected: "Your application has been rejected",
      application: "You have sent your application for this offer",
    },

    validationStep: {
      validation: "Confirmation",
      correspondingAvailability: "Corresponding availability",
      validateSchedule:
        "I make sure I am available for the hours requested in the offer schedule",
      validateDates:
        "I make sure I am available between the dates requested in the offer",
      startWhen: "I am available from ",
      endWhen: "I am available until ",
      travelTime: "Travel Time",
      validateTravelTime:
        "I make sure to not have more than one hour of travel time for the offer",
      validateApply: "I confirm my application",
      yes: "yes",
      no: "no",
    },

    applied: {
      title: "Application registered",
      congratulation: "Congratulations!",
      tips: "To maximize your chance of finding a family, we recommend that you also apply to other offers.",
      description:
        "Your application has been taken into account and we will review it in the coming days.",
      return: "I apply to more offers",
    },
    start: "Start",
    end: "End",
    perWeek: "per week",
    hour: "hour",
    grossSalary: "Gross salary",
    paidLeave: "Paid leave",
    transportation: "Transportation",
    detail: "Offer details",
    "see-detail": "See the offer details",
    type: "Type of offer",
    languageLevel: "Minimum language requirements",
    dates: "Dates of the offer",
    schedule: "Offer schedule",
    client: "The family",
    location: "Location",
    salary: "Salary",
    travelTime: "Travel Time",
    similarOffers: "Similar offers",
    apply: "Apply",
    unapply: "Remove my application",
    yearsOld: "years old",
    and: "and",
    "not-found": "Offer  n° {{ reference }} unknown",
    map: {
      "load-data": "Load the travel data",
    },
  },

  "sub-types": {
    initiation:
      "Initiation childcare: is in French with a 30 minutes introduction in English",
    immersion: "Immersion childcare: is 100% in a foreign language",
    creative:
      "Creative childcare: is 100% in French with lots of activities to do",
    course: "Courses: lesson-based moments",
  },

  "user-profile": {
    yourProfile: "My profile",
    informations: "My information",
    professionalExperiences: "My professional experiences",
    noExperiences: "No professional experience saved",
    newExperience: "New professional experience",
    communicationLanguage: "Communication Language",
    changeCommunicationLanguage:
      "To modify the language of your personal space and the language in which we send you your communications",
    password: "My password",
    resetPassword: "To reset your password, click on the button below.",
    resetMyPassword: "Reset my password",
    cancel: "Cancel",
    save: "Save",
    languages: {
      "en-GB": "English",
      "fr-FR": "French",
    },
    firstname: "First name",
    lastname: "Last Name",
    nationality: "Nationality",
    phoneNumber: "Phone number",
    email: "Email address",
    address: "Address",
    zipcode: "Zipcode",
    city: "City",
    experience: {
      experience: "Experience",
      duration: "Duration",
      "birth-date": "Date of birth",
      newBorn: "New born",
      type: "Type",
      from: "From",
      to: "To",
      month: "Month",
      year: "Year",
      children: "Children",
      noChildren: "No children registered",
      child: "Child",
      missions: "Missions",
      January: "January",
      February: "February",
      March: "March",
      April: "April",
      May: "May",
      June: "June",
      July: "July",
      August: "August",
      September: "September",
      October: "October",
      November: "November",
      December: "December",
      monthOld: "month",
      monthsOld: "months",
      yearsOld: "years",
      yearOld: "year",
      and: "and",
    },
  },

  "on-boarding": {
    welcome: {
      title: "Welcome to Mômji",
      text: "This space is now yours. You will be able to access our offers, apply and find a job!",
    },
    profile: {
      title: "Complete your profile",
      text: "The faster your profile will be completed, the faster families will see it and wish to meet you.",
    },
    offers: {
      title: "Our offers",
      text: {
        immersion: "Immersion childcare: is 100% in a foreign language",
        initiation:
          "Initiation childcare: is in French with a 30 minutes introduction in English",
        creative:
          "Creative Childcare: is in French with moments dedicated to creativity",
      },
    },
    helpcenter: "Our help center",
  },

  "my-offers": {
    applications: "Applications",
    bookmarks: "Bookmarks",
    pushes: "Suggestions",
  },
  career: {
    documents: {
      documents: "Documents",
      addFile: "Add a file or photo",
      JUSTIFICATIF: "Documentary proof that you can work in France",
      IDENTITE: "ID documents",
      OTHER: "Other documents requested",
      status: {
        init: "Requested",
        invalidated: "Declined",
        "to-validate": "Pending validation",
        validated: "Confirmed",
      },
      validate: "Validate",
      noDocumentsRequired: "No other documents required",
      "select-id-doc-type": "Select a type of document",
      acceptedFormat: "Accepted formats",
      maxSize: "Maximum size accepted",
      alreadyUploaded: "Document already uploaded",
      description: {
        IDENTITE:
          "Your ID document is required to edit documents related to your contracts with Mômji",
        JUSTIFICATIF:
          "The following documents are mandatory to allow you to work in France",
        OTHER: "The following documents are required for you to work in France",
      },
      question: {
        IDENTITE: "What type of ID document do you have?",
        JUSTIFICATIF: "What type of ID document do you have?",
        OTHER: "",
      },
      "list-label": {
        IDENTITE: "ID documents",
        JUSTIFICATIF: "Documentary proof",
        OTHER: "Other documents",
      },
    },
    "your-progress": {
      title: "Your progress",
    },
    quiz: {
      title: "Knowledge Quiz",
      description: "This quiz will test your knowledge about childcare",
      answer: "I answer the quiz",
    },
    engagement: {
      title: "Charter of Commitment",
      description:
        "The Charter of Commitment lists the elements to respect when you start working with a family. It is mandatory that you read it.",
      read: "I read the Charter of Commitment",
      "see-engagement": "See Charter of Commitment",
      "modal-title": "Charter of Commitment",
      check: "I have read the Charter",
      validate: "I confirm",
      "has-read": "You have read the Charter of Commitment",
    },
  },

  travelTime: {
    startingPointQuestion: "Address",
    enterStartingPoint:
      "Enter the address from which you will leave in order to calculate the approximate travel time",
    duration: "Duration",
    distance: "Distance",
    seeDirection: "See the directions",
  },

  "week-days": {
    title: "Day(s) of the week",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },

  "daytime-periods": {
    "all-day": "All day",
    1: "Oh - 12h",
    2: "12h - 16h",
    3: "16h - 19h",
    4: "19h - 24h",
  },
  "daytime-start-periods": {
    1: "Oh",
    2: "12h",
    3: "16h",
    4: "19h",
  },
  entities: {
    department: {
      list: "Departements",
      edit: "Edit the department",
      add: "Add a department",
    },
    api: {
      list: "APIs",
      edit: "Edit the API",
      add: "Add an API",
    },
    "role-type": {
      list: "Type of role",
      edit: "Edit the type of role",
      add: "Add a type of role",
    },
    action: {
      list: "Actions",
      edit: "Edit the action",
      add: "Add an action",
    },
    route: {
      list: "Routes",
      edit: "Edit the route",
      add: "Add a route",
    },
    role: {
      list: "Roles",
      edit: "Edit a role",
      add: "Add a role",
      routes: {
        title: "Associated routes",
        subtitle: "Select the routes you wish to associate to a role",
      },
    },
    user: {
      list: "Users",
      edit: "Edit a user",
      add: "Add a user",
    },
  },

  admin: {
    nav: "Administration EN",
  },

  dashboard: {
    welcome: "Welcome to Mômji!",
    "your-todo": " Your tasks",
    "generic-message": "",
    SEARCHJOB: {
      title: "Our job offers",
      description:
        "Take a look at our offers in Immersion, Initiation and Creative childcare and use the filters to adjust your search",
      action: "I take a look at the offers",
    },
    MYJOBS: {
      title: "Your offers",
      description:
        "Follow your applications, your favorites and the Mômji’s suggestions",
      action: "I follow the progress on my offers",
    },
    PROFIL: {
      title: "Your profile",
      description:
        "Fill in your ID information as well as your work experiences",
      action: "I fill in my information",
    },
    CONTACT: {
      title: "Contact Mômji",
      description: "",
      action: "I get in touch with Mômji",
    },
    PARCOURS: {
      title: "My Progress ",
      description:
        "Read about your progress in our process to know the following steps",
      action: "I take a look at my progress",
    },
    PLANNING: {
      title: "Your schedule",
      description:
        "Take a look at your schedule to get information on upcoming missions",
    },
    CONTRACTS: {
      title: "Your contracts",
      description: "Take a look at your past, current or pending contracts",
      action: "I take a look at my contracts",
    },
    BULLETINS: {
      title: "Your pay slips",
      description: "Take a look and download your pay slips",
      action: "I take a look at my pay slips",
    },
  },

  // Navigation (cf: dashboardItems.js)
  "permissions-api": {
    nav: "Permissions",
    departments: "Departements",
    apis: "APIs",
    roleTypes: "Role types",
    actions: "Actions",
    routes: "Routes",
    roles: "Roles",
    users: "Users",
  },
  users: {
    nav: "Utilisateurs",
    profile: "Profil",
  },

  validators: {
    wrongFormat: "Wrong format",
  },

  contract: {
    "no-result": "No contract",
    "no-result-description":
      "As soon as a family and you will have decided to work together, your contract will appear here",
    "begins-on": "Starts on",
    "ends-on": "Ends on",
    "linked-to-offer": "Linked to the offer n°",
    "offer-num": "Offer n°",
    status: {
      title: "Statut",
      Terminé: "Completed",
      "A signer": "To sign",
      "En cours": "In progress",
    },
    section: {
      contracts: "Contracts",
      due: "DUE",
      healthcare: "Mutual Healthcare",
    },
    "family-info": "The family’s contact details",
    email: "Email address:",
    phone: "Phone number:",
    address: "Address:",
    action: {
      file: "See my contract",
      sign: "I sign my contract",
    },
    healthcare: {
      "momji-obligation":
        "Mômji is legally obliged to set up a health mutual insurance (complementary healthcare) for its employees.",
      "your-obligation":
        "You must follow this procedure whether or not you wish to join the health mutual insurance company.",
      title: "Health mutual insurance for",
      refused: "You have decided not to join the health mutual insurance.",
      accepted: "You have decided to join the health mutual insurance .",
      link: "See the table of benefits of the health mutual insurance ",
      fee: "If you subscribe to the health mutual insurance, you will be deducted 50% x 20.23€ every month, regardless of the number of hours you have worked.",
      resiliation:
        "If you join, you will not be able to cancel your membership for 1 year or for the duration of your contract if it is less than 1 year.",
      inflation:
        "For your information, the monthly fee may increase each year. Mômji cannot influence this price evolution",
      information:
        "Reminder: to subscribe to the health mutual insurance, you must first be registered with the Caisse Primaire d'Assurance Maladie (CPAM) and have a French social security number. To obtain this number, you must submit a complete file to your CPAM. It takes between 4 and 8 months to obtain the number.",
      "yes-choice": "I wish to join the health mutual insurance",
      "no-choice": "I do not wish to join the health mutuel insurance",
      validate: "Confirm my choice",
      "you-accepted":
        "You have chosen to join the health mutual insurance. To finalize your membership, please download and complete: ",
      "form-link": "The membership form",
      "send-us":
        "Then, send us the membership form as well as your certificate of entitlement to social security and your French bank details, to the address:",
      "when-complete":
        "When your file will be completed, you will receive from the health mutual insurance: ",
      card: "• your mutual insurance card",
      "web-access": "• your access to https://secure.aggema.com",
      "will-end":
        "Your membership to the health mutual insurance will end when your contract with the family will be completed",
      "more-info":
        "For more information, you can contact the health mutual insurance directly at this email address gestionsante@aggema.fr or by phone at +33 01 41 90 98 58",
      "action-button": {
        choose: "Make my choice",
        sign: "Sign my statement",
        complete: "Complete my file",
      },
      "see-document": "See the document",
      "waranties-link":
        "See the table of benefits of the health mutual insurance",
      "accept-title": "Membership",
      "refuse-title": "Rejection of membership",
      "complete-title": "Complete my file",
    },
    due: {
      description:
        "The single declaration of employment (DUE) is a document provided by Mômji when you are hired as an employee. It allows you to fill in several declarative obligations linked to the hiring process in one go.",
      employer: "Employer",
      "begins-on": "Starts on",
      "see-action": "See my DUE (single declaration of employment)",
    },
    "date-change": {
      action: "Change my departure date",
      title: "Change my departure date",
      reason: "For what reason would you like to change your departure date?",
      "available-until": "I am now available until :",
      validate: "Confirm the change",
    },
  },

  payslips: {
    payslips: {
      description:
        "To receive your salary, you must have a European bank account.",
      "see-rib": "See my bank statement",
      "enter-rib": "Fill in my bank statement",
    },
    rib: {
      title: "Bank Account Statement",
      description:
        "To receive your salary, you must have a European bank account",
      "no-file": "Missing file",
      "file-uploaded": "A file has already been provided",
      "your-upload": "Your file",
      name: "Last name and First name",
      domiciliation: "Bank address",
      bank: "Bank",
      iban: "Iban",
      bic: "BIC/SWIFT",
      edit: "Edit",
      cancel: "Cancel",
      save: "Save",
    },
  },

  mandatory: {
    disponibility: {
      title: "Availability",
      description: "Last time we checked, you were available until",
      "description-no-date":
        "Last time we checked, you were available for an indefinite period of time",
      question: "Is this still the case?",
      yes: "Yes",
      no: "No, I am available until",
      "no-date": "No, I don’t know until when I’ll be available",
      validate: "Confirm",
    },

    "contract-disponibility": {
      title: "My contracts",
      description:
        "Please find below the list of your current jobs. If the date of one of your missions has changed, you must inform us as quickly as possible",
      "start-on": "Starts on",
      "end-on": "Ends on",
      "my-end-date": "My expected departure date: ",
      "choice-saved": "Your answer has been taken into account",
      "change-date": "Change my departure date",
      "confirm-date": "Keep my current departure date",
    },
  },

  planning: {
    actions: {
      button: "Actions",
      coupon: "Enter a coupon",
    },
  },
};

export { translations };
