import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Menu,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";

import calendarStyle from "./Calendar.style";

const FullCalendarWrapper = styled.div`
  ${calendarStyle}
`;

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const PlanningView = (props) => {
  const { events, onMonthChange } = props || [];
  const { t, i18n } = useTranslation();
  const calendarRef = useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);

  const language = i18n.language;

  const locales = {
    fr: frLocale,
  };

  const handleMonthChange = (payload) => {
    console.log("[PlanningView] handleMonthChange", payload);
    onMonthChange({ startDate: payload.start, endDate: payload.end });
  };

  const handleActionsOpen = (e) => {
    setAnchor(e.currentTarget);
  };

  const handleActionsClose = () => {
    setAnchor(null);
  };

  const handleEventClick = (e) => {
    console.log("EVENT:", e);
    //e.event.remove();
  };

  const handleActionCoupon = () => {
    console.log("[PlanningView] handleActionCoupon");
    handleActionsClose();
  };

  /* const handleDateClick = (e) => {
    console.log("DATE:", e);
    const event = {
      id: e.date,
      title: "Event Test",
      start: e.dateStr,
    };
    addEvent(event);
  };/*

  /*const addEvent = (event) => {
    const api = calendarRef.current.getApi();
    api.addEvent(event);
  };*/

  const renderActionsMenu = () => {
    return (
      <Box>
        <Menu
          id="basic-menu"
          anchorEl={anchor}
          open={open}
          onClose={handleActionsClose}
        >
          <MenuItem onClick={handleActionCoupon}>
            {t("planning.actions.coupon")}
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  return (
    <Box>
      <Card mb={6}>
        <CardContent p={6}>
          <FullCalendarWrapper>
            <FullCalendar
              ref={calendarRef}
              titleFormat={{
                year: "numeric",
                month: fullScreen ? "short" : "long",
              }}
              initialView="dayGridMonth"
              initialDate={new Date()}
              plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
              events={events}
              datesSet={handleMonthChange}
              slotMinTime="06:00:00"
              eventTimeFormat={{
                hour: "2-digit",
                minute: "2-digit",
                meridiem: false,
                hour12: false,
              }}
              editable={true}
              //dateClick={handleDateClick}
              eventClick={handleEventClick}
              headerToolbar={{
                left: "prev,next",
                center: "title",
                right: "actionsButton",
              }}
              customButtons={{
                actionsButton: {
                  text: t("planning.actions.button"),
                  click: handleActionsOpen,
                },
              }}
              locale={language === "en" ? null : locales[language]}
              contentHeight="auto"
            />
          </FullCalendarWrapper>
        </CardContent>
      </Card>
      {renderActionsMenu()}
    </Box>
  );
};

export default PlanningView;
