import {
  Button,
  // Menu,
  // MenuItem
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
// import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import SortIcon from "@mui/icons-material/Sort";
import React from "react";
import i18n from "i18next";

/**
 * Returns options
 * @returns {Array}
 */
export const sortAndSortByOptions = [
  {
    label: `${i18n.t("search-job.sorting.label")}`,
    dataField: "_score",
    sortBy: "asc",
  },
  {
    label: `${i18n.t("search-job.sorting.distance")} - ${i18n.t(
      "search-job.sorting.asc"
    )}`,
    dataField: "geo-distance",
    sortBy: "asc",
  },
  {
    label: `${i18n.t("search-job.sorting.hours-by-week")} - ${i18n.t(
      "search-job.sorting.asc"
    )}`,
    dataField: "hours_by_week",
    sortBy: "asc",
  },
  {
    label: `${i18n.t("search-job.sorting.hours-by-week")} - ${i18n.t(
      "search-job.sorting.desc"
    )}`,
    dataField: "hours_by_week",
    sortBy: "desc",
  },
  {
    label: `${i18n.t("search-job.sorting.starting-date")} - ${i18n.t(
      "search-job.sorting.asc"
    )}`,
    dataField: "start_date",
    sortBy: "asc",
  },
  {
    label: `${i18n.t("search-job.sorting.starting-date")} - ${i18n.t(
      "search-job.sorting.desc"
    )}`,
    dataField: "start_date",
    sortBy: "desc",
  },
];

/**
 * Returns SortAndSortBySelector
 * @param {Object} props
 * @returns {ReactComponent}
 */
const SortAndSortBySelector = (props) => {
  const { handleChange, selected, currentFilters } = props;
  //  <Menu> version
  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  //  <Drawer> version
  const [state, setState] = React.useState({
    bottom: false,
  });
  const DRAWER_ANCHOR = "bottom";

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleSelect = (opt) => () => {
    handleChange(opt);
    // handleClose();
  };

  /**
   * Returns if entry is disabled or not
   * @param {String} dataField
   * @returns {Boolean}
   */
  const isEntryDisabled = (dataField) => {
    // console.log(
    //   "[SortAndSortBySelector] isEntryDisabled",
    //   dataField,
    //   currentFilters
    // );

    if (dataField === "geo-distance") {
      if (
        currentFilters &&
        typeof currentFilters["geo-distance"] !== "undefined"
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === DRAWER_ANCHOR ? "auto" : 250,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {sortAndSortByOptions.map((opt, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={handleSelect(opt)}
              disabled={isEntryDisabled(opt.dataField)}
            >
              {opt.label}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div className="sort-selector-container">
      {/* <span>Trier par :</span> */}
      <Button
        className="sort-options-btn"
        aria-controls="sort-options-menu"
        aria-haspopup="true"
        variant="outlined"
        startIcon={<SortIcon />}
        onClick={toggleDrawer(DRAWER_ANCHOR, true)}
        // onClick={handleClick}
      >
        {selected.label}
      </Button>

      <Drawer
        id="sort-options-menu"
        anchor={DRAWER_ANCHOR}
        open={state[DRAWER_ANCHOR]}
        onClose={toggleDrawer(DRAWER_ANCHOR, false)}
      >
        {list(DRAWER_ANCHOR)}
      </Drawer>

      {/* <Menu
        id="sort-options-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {sortAndSortByOptions.map((opt, index) => (
          <MenuItem key={index} onClick={handleSelect(opt)}>
            {opt.label}
          </MenuItem>
        ))}
      </Menu> */}
    </div>
  );
};

export default SortAndSortBySelector;
