//import { hot } from "react-hot-loader/root";
import React from "react";
import { LogglyProvider } from "react-loggly-jslogger";

import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

import { appConfig } from "./config";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";

import { XSRFProvider } from "./contexts/XSRFContext";
import { AlertProvider } from "./contexts/AlertContext";
import { AuthProvider } from "./contexts/SAMAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
import { SearchJobProvider } from "./contexts/SearchJobContext";

import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

import { isMobileDevice, initConsole } from "./helpers/common.helpers";

import { useLocation } from "react-router-dom";

import "./app-styles.scss";

// Enable / disable window.console
initConsole(appConfig.env === "development", ["error", "warn"]);

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

// Custom LogglyTracker initialization options
// Sensible React defaults (including your customer token) are automatically applied
const logglyOptions = {};

// Custom data can be appended using provider callbacks
// Additional providers can be added later down the component tree (see examples below)
const logglyProviders = {
  // Custom "foo" data added to error messages only
  env: (instance, key, level, data) => {
    if ("error" === level) {
      return {
        [key]: appConfig.env,
      };
    }
  },
};

function App() {
  console.info("[App] appConfig: ", appConfig);
  console.info("[App] isMobileDevice: ", isMobileDevice());
  const content = useRoutes(routes);

  const location = useLocation();

  const { theme } = useTheme();

  const titleTemplate = `%s | ${appConfig.description}`;
  const defaultTitle = `${appConfig.description} - v${appConfig.version}`;

  return (
    <LogglyProvider options={logglyOptions} providers={logglyProviders}>
      <ErrorBoundary location={location}>
        <HelmetProvider>
          <Helmet titleTemplate={titleTemplate} defaultTitle={defaultTitle} />
          <Provider store={store}>
            <StylesProvider jss={jss}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StyledEngineProvider injectFirst>
                  <MuiThemeProvider theme={createTheme(theme)}>
                    <ThemeProvider theme={createTheme(theme)}>
                      <AuthProvider>
                        <XSRFProvider>
                          <AlertProvider>
                            <SearchJobProvider>{content}</SearchJobProvider>
                          </AlertProvider>
                        </XSRFProvider>
                      </AuthProvider>
                    </ThemeProvider>
                  </MuiThemeProvider>
                </StyledEngineProvider>
              </LocalizationProvider>
            </StylesProvider>
          </Provider>
        </HelmetProvider>
      </ErrorBoundary>
    </LogglyProvider>
  );
}

// export default hot(App);
export default App;
