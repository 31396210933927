import React from "react";

import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import ContractsView from "./ContractsView";
import ContractDetail from "../../components/contracts/ContractDetail";
import HealthCare from "../../components/contracts/HealthCare";

import { SECTIONS } from "./constants";

function Contracts() {
  const { t } = useTranslation();
  const { id, section } = useParams();

  const renderDetailView = () => {
    if (SECTIONS[section] === SECTIONS.healthcares)
      return <HealthCare id={id} />;
    else return <ContractDetail id={id} />;
  };

  return (
    <React.Fragment>
      <Helmet title={t("menu.contracts.contracts")} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {id ? renderDetailView() : <ContractsView section={section} />}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Contracts;
