import React, { useState } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import {
  Box,
  Checkbox,
  Divider as MuiDivider,
  FormGroup,
  FormControlLabel,
  Typography,
} from "@mui/material/";
import { spacing } from "@mui/system";

import TravelTime from "./TravelTime";

const Divider = styled(MuiDivider)(spacing);

const TravelTimeValidator = (props) => {
  const { offer, conditionValidate, setUserTravelInfos } = props;
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [travelTimeCalculated, setTravelTimeCalculated] = useState(false);

  /**
   * Handle validation checkbox changing
   * @returns {void}
   */
  const handleValidateChange = () => {
    setValidated(!validated);
    conditionValidate("travelTime", !validated);
  };

  /**
   * Update UserTravelInfos
   * @param {Object} travelInfos
   * @returns {void}
   */
  const handleTravelInfosChange = async (travelInfos) => {
    console.log("[TravelTimeValidator] handleTravelInfosChange:", travelInfos);
    setUserTravelInfos(travelInfos);
  };

  return (
    <Box>
      <Typography sx={{ fontWeight: "bold" }} variant="h6">
        {t("job-offer.validationStep.travelTime")}
      </Typography>
      <Divider my={6} />
      <TravelTime
        offer={offer}
        calculationDone={setTravelTimeCalculated}
        onTravelInfosChange={handleTravelInfosChange}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              disabled={!travelTimeCalculated}
              onChange={handleValidateChange}
            />
          }
          label={
            <Typography>
              {t("job-offer.validationStep.validateTravelTime")}
            </Typography>
          }
        />
      </FormGroup>
    </Box>
  );
};

export default TravelTimeValidator;
